@font-face {
  font-family: 'graphik';
  src:  url('./fonts/graphik/Graphik-Light.otf') format('otf'),
        url('./fonts/graphik/Graphik-Regular.otf') format('otf'),
        url('./fonts/graphik/Graphik-Medium.otf') format('otf'),
        url('./fonts/graphik/Graphik-Semibold.otf') format('otf'),
        url('./fonts/graphik/Graphik-Bold.otf') format('otf'),
        url('./fonts/graphik/Graphik-BoldItalic.otf') format('otf');
}
@import url('https://fonts.cdnfonts.com/css/graphik-trial');
@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;400;500;600;700&display=swap');


*{
  font-family: 'Rajdhani' !important;
}


