.scroll {
  position: fixed;
  bottom: 10px;
  right: 20px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  background-color: $color-main;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: hvr-hang 1s linear infinite;
  &__arrow {
    width: 70%;
    height: auto;
    color: #fff;
  }
}
