.subscribe {
  &__container {
    width: 30%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include respond(tab-port) {
      width: 85%;
    }
  }
  p {
    font-size: 1rem;
    color: #1f1035;
    letter-spacing: 0.01em;
    align-self: stretch;
  }
  &_email {
    border: 1px solid #f5f6f8;
    box-sizing: border-box;
    border-radius: 6px;
    width: 100%;
    height: 40px;
    padding: 0 10px;
    font-size: 1em;
    align-self: stretch;
    &:focus {
      outline: none;
    }
  }

  &__img {
    position: absolute;
    top: 8px;
    right: 20px;
    border: none;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #773dd3;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}
