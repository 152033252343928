.contact {
  background: #1f1035;

  position: fixed;
  top: 0;
  bottom: 0;

  width: 100vw; /* viewport width */
  height: 100%; /* viewport height */
  overflow-y: auto;
  // overflow-x: hidden;

  z-index: 100000;

  &__container {
    width: 100%;
    padding: 4rem 0;
  }

  &__header {
    width: 90%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    padding: 1rem 0 0 0;
    @media screen and (max-width: 610px) {
      width: 80%;
    }

    &-cancel {
      width: 30px;
      height: 30px;
      background: #f2f2f2;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      & > span {
        display: inline-block;
        font-size: 2rem;
        color: #152c5b;
        font-weight: bold;
      }
    }
  }

  &__form {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 4rem;
    &__container {
      width: 610px;
      margin: auto;

      max-width: 80%;
      & > h1 {
        font-weight: 600;
        font-size: 4.17rem;
        line-height: 4.6rem;
        text-align: center;

        color: #ffffff;
      }

      & > p {
        font-weight: normal;
        font-size: 1.5rem;
        line-height: 5rem;
        text-align: center;
        letter-spacing: 0.01em;

        color: #ffffff;
      }
    }
  }

  &__button {
    padding: 1.3rem 0;
    width: 100%;
    background: #ffffff;
    border-radius: 10px;
    letter-spacing: 0.03em;
    font-weight: 600;

    color: #773dd3;
    font-size: 1.3rem;
    border: none;

    &:disabled {
      @include disabled
    }
  }

  &__meta {
    margin-top: 3rem;
    display: flex;
    justify-content: space-between;
    color: #ffffff;
    margin-bottom: 3rem;

    @media screen and (max-width: 600px) {
      flex-direction: column;
    }
    &-sm {
      @media screen and (max-width: 600px) {
        margin-bottom: 3rem;
      }
      p {
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0.06em;
      }
      div {
        margin-top: 2rem;

        a:not(:last-child) {
          margin-right: 2rem;
        }
      }
    }

    & > p {
      font-size: 16px;
      align-self: flex-end;
      letter-spacing: 0.06em;
      margin-bottom: 0;
      @media screen and (max-width: 600px) {
        align-self: flex-start;
      }
    }

    &-adr {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      font-size: 16px;
      @media screen and (max-width: 600px) {
        margin-top: 3rem;
      }
      p {
        margin-bottom: 0 !important;
      }
      p:first-of-type {
        font-weight: 600;
      }
    }
  }
}
