.main {
  background: rgba(31, 16, 53, 0.1);
  backdrop-filter: blur(20px);
  z-index: 1000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: fixed;
  /* display: none; */
}

.container {
  box-sizing: border-box;
  width: 740px;
  height: 85vh;
  border-radius: 24px;
  background: #fff;
  margin: 70px auto;
  overflow: auto;
}
.innerContainer {
  width: 540px;
  margin: auto;
  align-items: center;
}
.calender {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  font-family: Graphik Trial;
  /* color: #afbaca; */
  color: #575a65;
  display: flex;
  width: 100%;
  height: 48px;
  padding: 0 12px;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #afbaca;
  background: #fff;
  margin: 0 auto 16px;
  cursor: pointer;
}
.form{
  width: 100%;
  height: fit-content;
}
.form input,
select,
textarea {
  box-sizing: border-box;
  font-family: Graphik Trial;
  /* color: #afbaca; */
  color: #575a65;
  display: flex;
  width: 100%;
  height: 48px;
  padding: 0 12px;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #afbaca;
  background: #fff;
  margin: 0 auto 16px;
}
.text h3 {
  color: #333;
  font-family: Graphik Trial;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1.2px;
  margin-bottom: 16px;
  padding-top: 28.5px;
}
.text p {
  color: #333;
  text-align: center;
  font-family: Graphik Trial;
  font-size: 14px;
  line-height: 150%; /* 21px */
  letter-spacing: 0.28px;
}
.text p span {
  color: #773dd3;
  font-family: Graphik Trial;
  font-size: 14px;
  font-style: normal;
  line-height: 150%;
  letter-spacing: 0.28px;
}
.btn {
  border-radius: 8px;
  background: #773dd3;
  display: flex;
  width: 275px;
  height: 56px;
  padding: 14px 39px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #fff;
  font-family: Graphik Compact Trial;
  font-size: 12px;
  font-style: normal;
  letter-spacing: 0.36px;
  border: none;
  margin: auto;
  margin-bottom: 19px;
  cursor: pointer;

}
.text {
  text-align: center;
  margin-bottom: 24px;
}
.time div {
  display: flex;
  width: 206px;
  padding: 10px 0px;
  align-items: center;
  gap: 8px;
  border-bottom: 1px solid #dbdbdb;
  color: #333;
  font-family: Graphik Trial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;

}
.close {
  position: fixed;
  top: 90px;
  right: 350px;
  height: 1.875rem;
  width: 1.875rem;
  border-radius: 50%;
  background-color: #773dd3;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.close span {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: 41px;
  text-align: center;
  font-variant: small-caps;
  color: #ffffff;
}
@media screen and (max-width: 1140px) {
  .close {
    right: 200px;
  }
}
@media screen and (max-width: 768px) {
  .close {
    right: 90px;
  }
  .container {
    width: 90%;
  }
  .innerContainer {
    width: 90%;
  }
}
@media screen and (max-width: 628px) {
  .form input,
  select,
  textarea {
    width: 90%;
  }
  .calender {
    width: 90%;
  }
  h3 {
    font-size: 35px;
    margin-bottom: 8px;
    padding-top: 18px;
  }
  .close {
    right: 50px;
  }
}
@media screen and (max-width: 375px) {
  .btn {
    width: 70%;
  }
  .close {
    right: 30px;
  }
}
