//For Desktop responsiveness view
$desktop: 1201px;
//For Tablet responsiveness view
$tablet: 840px;
//For Mobile responsiveness view
$mobile: 839px;

// Colors
$color-white: #ffffff;
$color-main: #773DD3;

