.value {
  background: #ffffff;
  border-radius: 10px;
  width: fit-content;
  transition: all 0.3s ease-in;
  &:hover {
    transform: translateY(-0.5rem) scale(1.05);
  }
  &__container {
    @include container;
    padding: 3rem 0;
    @media screen and (max-width: 800px) {
      padding: 4rem 0;
    }
    h2 {
      font-weight: 600;
      font-size: 38px;
      line-height: 50px;

      color: #f5f5f5;
    }
  }

  &__content {
    margin-top: 30px;
  }
}
