// .bold {
//   font-weight: bold;
// }

// .innovation {
//   overflow-x: hidden;
//   &_toptext {
//     margin: 100px 0 70px;
//     p {
//       font-size: 1rem;
//       color: $color-main;
//       @media (max-width: 600px) {
//         font-size: 2rem;
//       }
//     }
//     h1 {
//       color: #1f1035;
//       font-size: 3em;
//       @media (max-width: 600px) {
//         font-size: 4.2rem;
//       }
//     }
//   }

//   &_hero {
//     padding: 100px 0px;
//     position: relative;
//     background: url("../images/website/IMG_9683.jpg");
//     background-size: cover;
//     background-position: left bottom -50px;

//     &-text {
//       color: white;
//       h2 {
//         font-size: 3rem;
//         @media (max-width: 600px) {
//           font-size: 4.5rem;
//         }
//       }
//       p {
//         @media (max-width: 600px) {
//           font-size: 1.8rem;
//         }
//       }
//       button {
//         color: $color-white;
//         background: $color-main;
//         border: 1px solid $color-main;
//         font-size: 1rem;
//         padding: 10px 25px;
//         margin: 20px 0px;
//         border-radius: 5px;
//         @media (max-width: 600px) {
//           font-size: 2rem;
//         }
//       }
//       @media (min-width: 900px) {
//         width: 600px;
//       }
//     }
//     // &-bg {
//     //   background: rgba(31, 16, 53, 0.1);
//     //   backdrop-filter: blur(20px);
//     //   z-index: 1000;
//     //   top: 0;
//     //   left: 0;
//     //   bottom: 0;
//     //   right: 0;
//     //   position: fixed;
//     // }
//     // &-form {
//     //   position: fixed;
//     //   top: 10vh;
//     //   border-radius: 8px;
//     //   padding: 20px;
//     //   background: $color-white;
//     //   @media (min-width: 550px) {
//     //     left: 30%;
//     //     right: 30%;
//     //   }
//     //   @media (max-width: 550px) {
//     //     width: 100%;
//     //     box-sizing: border-box;
//     //   }

//     //   p {
//     //     color: #575a65;
//     //     margin-left: 10px;
//     //     @media (max-width: 600px) {
//     //       font-size: 2rem;
//     //     }
//     //   }
//     //   input,
//     //   select {
//     //     box-sizing: border-box;
//     //     border: 1px solid #e0e0e0;
//     //     font-size: 1rem;
//     //     height: 40px;
//     //     padding: 0 10px;
//     //     width: 100%;
//     //     margin: 10px 5px;
//     //     @media (max-width: 600px) {
//     //       font-size: 2rem;
//     //     }
//     //   }
//     //   select {
//     //     color: #575a65;
//     //   }

//     //   button {
//     //     height: 40px;
//     //     background: $color-main;
//     //     border: 1px solid $color-main;
//     //     color: white;
//     //     border-radius: 8px;
//     //     font-size: 0.9rem;
//     //     padding: 7px 15px;
//     //     margin: 10px 0;
//     //     @media (max-width: 600px) {
//     //       font-size: 2rem;
//     //     }
//     //   }

//       &-flexinput {
//         @media (min-width: 1000px) {
//           display: flex;
//           justify-content: space-between;
//         }
//         &:first {
//           @media (min-width: 1000px) {
//             margin-right: 10px;
//           }
//         }
//       }
//     }
//   }

//   &_spaces {
//     margin: 100px 0;
//     @media screen and (max-width: 800px) {
//       display: none;
//     }
//     p {
//       font-size: 1rem;
//       @media (max-width: 600px) {
//         font-size: 2rem;
//       }
//     }
//     h1 {
//       @media (max-width: 600px) {
//         font-size: 3.5rem;
//       }
//     }
//     &-offices {
//       @media (min-width: 600px) {
//         display: flex;
//       }
//       &-selection {
//         margin-top: 40px;
//         font-size: 1rem;
//         cursor: pointer;
//         @media (max-width: 600px) {
//           font-size: 2rem;
//           width: 100%;
//           display: grid;
//           grid-template-columns: 25% 25% 25% 25%;
//         }
//         @media (min-width: 900px) {
//           min-width: 250px;
//         }
//       }
//       &-view {
//         margin: 20px;
//         width: calc(100vw-400px);
//         .images {
//           width: calc(100vw-400px);
//           border-radius: 10px;
//           position: relative;
//           overflow: hidden;
//           img {
//             width: 100%;
//           }
//         }
//         .about {
//           margin-top: 20px;
//         }
//         .link {
//           color: $color-main;
//         }
//       }
//     }
//   }

//   &_perks {
//     box-sizing: border-box;

//     @media screen and (max-width: 800px) {
//       margin-top: 5rem;
//     }
//     .text1 {
//       font-size: 1rem;
//       color: #575a65;
//       @media (max-width: 600px) {
//         font-size: 2rem;
//       }
//     }
//     @media (min-width: 600px) {
//       display: flex;
//       &-firstdiv {
//         width: 50%;
//       }
//       &-seconddiv {
//         width: 50%;
//       }
//     }
//     &_list {
//       display: flex;
//       @media (max-width: 600px) {
//         margin-bottom: 120px;
//       }
//       &-1 {
//         width: 50%;
//       }
//       &-2 {
//         width: 50%;
//       }
//       &-item {
//         display: flex;
//         margin: 20px 10px 10px 0;
//         .svg-box2 {
//           width: 40px;
//           height: 40px;
//           margin-right: 10px;
//           border-radius: 5px;
//           background: #f3efff;
//           display: flex;
//           justify-content: center;
//           align-items: center;
//         }
//         p {
//           font-size: 1rem;
//           color: #575a65;
//           @media (max-width: 600px) {
//             font-size: 2rem;
//           }
//         }
//         h1 {
//           font-size: 1.5em;
//           @media (max-width: 600px) {
//             font-size: 2.7rem;
//           }
//         }
//       }
//     }

//     &-imaging {
//       width: 100%;
//       position: relative;
//       padding-right: 50px;
//       @media (max-width: 599px) {
//         display: none;
//       }

//       img {
//         border-radius: 20px;
//         width: 100%;
//       }
//       &-box {
//         width: 250px;
//         height: 250px;
//         background: $color-main;
//         position: absolute;
//         z-index: 100;
//         top: -100px;
//         padding: 20px;
//         color: white;
//         border-radius: 10px;
//         right: 0;
//         p {
//           font-size: 0.9em;
//           @media (max-width: 600px) {
//             font-size: 2rem;
//           }
//         }
//         h3 {
//           font-size: 1.2em;
//           @media (max-width: 600px) {
//             font-size: 2.1rem;
//           }
//         }
//         .svg-box1 {
//           width: 30px;
//           height: 30px;
//           background: white;
//           border-radius: 10px;
//           margin-bottom: 10px;
//         }
//       }
//     }
//   }

//   &_gallery {
//     background: #1f1035;
//     color: white;
//     position: relative;
//     padding: 35vw 0 50px;
//     margin-top: 20vw;

//     h1 {
//       @media (max-width: 600px) {
//         font-size: 3.5rem;
//       }
//     }
//     &-images {
//       display: flex;
//       position: absolute;
//       top: -10vw;
//       &-one {
//         width: 25vw;
//         background-color: gray;
//         height: 25vw;
//         margin-top: 15vw;
//         overflow: hidden;
//       }
//       &-two {
//         width: 50vw;
//         overflow: hidden;
//         background-color: gray;
//         margin: 0 10px;
//         height: 40vw;
//         border-radius: 15px;
//       }
//       .first-box {
//         border-radius: 0px 15px 15px 0px;
//       }
//       .third-box {
//         border-radius: 15px 0px 0px 15px;
//       }
//       img {
//         width: auto;
//         height: 100%;
//       }
//     }
//     button {
//       padding: 10px 45px 10px 15px;
//       margin-top: 40px;
//       font-size: 1rem;
//       color: $color-white;
//       border-radius: 5px;
//       position: relative;
//       @media (max-width: 600px) {
//         font-size: 2rem;
//       }

//       img {
//         width: 23px;
//         position: absolute;
//         top: 10px;
//         right: 10px;
//       }
//     }
//     .first {
//       margin-right: 20px;
//       background: $color-main;
//       border: 1px solid $color-main;
//     }
//     .sec {
//       background: transparent;
//       border: 1px solid white;
//     }
//     p {
//       font-size: 1rem;
//       @media (max-width: 600px) {
//         font-size: 2rem;
//       }
//     }
//   }
//   &_feedback {
//     margin: 70px 0 0 100px;
//     @media (max-width: 760px) {
//       margin: 50px 0 0 30px;
//     }
//     h1 {
//       @media (max-width: 600px) {
//         font-size: 3.5rem;
//       }
//     }
//     p {
//       font-size: 1rem;
//       @media (max-width: 600px) {
//         font-size: 2rem;
//       }
//     }
//     h3 {
//       font-size: 1.4em;
//       @media (max-width: 600px) {
//         font-size: 2.7rem;
//       }
//     }

//     &-testimonial {
//       padding: 40px 0;
//       overflow-x: scroll;
//       white-space: nowrap;
//       width: 50%;
//       margin: auto;
//       display: grid;
//       grid-template-columns: repeat(3, 1fr);

//       @include respond(tab-port) {
//         width: 100%;
//       }
//       @media (min-width: 600px) {
//         &::-webkit-scrollbar {
//           display: none;
//         }
//       }
//       &-box {
//         width: 280px;
//         display: inline-block;
//         padding: 20px;
//         margin-right: 30px;
//         text-align: center;
//         white-space: normal;
//         box-shadow: 0px 4px 14px rgba(151, 161, 184, 0.1);
//         border-radius: 10px;
//         p {
//           font-size: 1rem;
//           color: #575a65;
//           @media (max-width: 600px) {
//             font-size: 2rem;
//           }
//         }
//       }
//     }
//   }

//   &_community {
//     background: url("../images/svg/pattern1.svg");
//     background-repeat: no-repeat;
//     background-size: cover;
//     background-color: $color-main;
//     background-position-x: right;
//     color: white;
//     text-align: center;
//     margin: 100px 0;
//     padding: 70px 30px;

//     h1 {
//       @media (max-width: 600px) {
//         font-size: 3.5rem;
//       }
//     }
//     p {
//       font-size: 1rem;
//       @media (max-width: 600px) {
//         font-size: 2rem;
//       }
//     }
//     button {
//       padding: 10px 45px 10px 15px;
//       margin-top: 40px;
//       font-size: 1rem;
//       color: $color-white;
//       border-radius: 5px;
//       position: relative;
//       color: $color-main;
//       border: 1px solid white;
//       background: white;
//       @media (max-width: 600px) {
//         font-size: 2rem;
//       }

//       img {
//         width: 23px;
//         position: absolute;
//         top: 10px;
//         right: 10px;
//       }
//     }
//   }
// }

.innovation {
  &_hero {
    background-image: url(/src/images/book/Hero.png);
    // background-color: #140e1f;
    background-repeat: no-repeat;
    // background-size: contain;
    padding: 200px 0;
    margin: 0 auto;
    // margin-bottom: 80px;
    width: 1440px;
    @media screen and (max-width: 1440px) {
      width: 100%;
    }
    @media screen and (max-width: 768px) {
      padding: 130px 0;
      background-image: url(/src/images/book/joy-mobile.png);
      background-size: cover;
    }
    @media screen and (max-width: 430px) {
      padding: 57px 0;
    }
    &_text {
      width: 722px;
      text-align: center;
      margin: auto;
      @media screen and (max-width: 768px) {
        width: 90%;
      }
      @media screen and (max-width: 430px) {
        width: 95%;
      }
      h4 {
        font-family: Graphik Trial;
        font-size: 56px;
        font-weight: 600;
        line-height: 67px;
        color: #ffff;
        @media screen and (max-width: 768px) {
          font-size: 50px;
          line-height: 54px;
        }
        @media screen and (max-width: 430px) {
          font-size: 40px;
          line-height: 44px;
        }
      }
      span {
        color: #d4baff;
        // border-bottom: 4px solid #d4baff;
        text-decoration: underline;
      }
      button {
        padding: 17px 53px;
        background-color: #773dd3;
        border-radius: 8px;
        font-family: Graphik Trial;
        font-size: 18px;
        font-weight: 600;
        line-height: 22px;
        border: none;
        color: #ffff;
        margin-top: 32px;
        @media screen and (max-width: 768px) {
          margin-top: 32px;
        }
      }
    }
  }
  &_offer {
    background-color: #fcf9ff;
    padding: 80px 0;
    @media screen and (max-width: 768px) {
      padding: 40px 0;
    }
    &_wrapper2 {
      display: none;
   
      @media screen and (max-width: 768px) {
        display: flex;
        justify-content: center;
        flex-direction: column;

      }
      &_btn {
        display: flex;
        width: 90%;
        justify-content: center;
        margin: 0 auto;
      }
      button {
        // display: flex;
        // text-align: center;
        width: 290px;
        border-radius: 8px;
        padding: 15px 0px;
        border: 1px solid #dddddd;
        color: #fff;
        font-family: Graphik Trial;
        font-size: 18px;
        font-weight: 600;
        line-height: 27px;
        background-color: #773dd3;
        @media screen and (max-width: 425px) {
          width: 95%;
        }
      }
    }

    &_wrapper {
      width: 1440px;
      margin: 0 auto;
      @media screen and (max-width: 1440px) {
        width: 100%;
      }

      &_text {
        text-align: center;
        margin: 0 auto;
        position: relative;
        h5 {
          font-family: Graphik Trial;
          font-size: 48px;
          font-weight: 600;
          line-height: 72px;
          color: #333333;
          margin-bottom: 16px;
          position: relative;
          z-index: 1;

          @media screen and (max-width: 768px) {
            font-size: 40px;
            line-height: 65px;
          }
        }
        p {
          font-family: Graphik Trial;
          font-size: 24px;
          font-weight: 400;
          line-height: 30px;
          color: #646464;

          width: 842px;
          margin: 0 auto;
          margin-bottom: 32px;
          @media screen and (max-width: 768px) {
            width: 95%;
            font-size: 18px;
          }
        }
      }

      &_btnFlex {
        width: 80%;
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        gap: 25px;
        @media screen and (max-width: 1024px) {
          width: 90%;
        }
        @media screen and (max-width: 768px) {
          display: none;
        }
        button {
          width: 290px;
          border-radius: 8px;
          padding: 15px 0px;
          border: 1px solid #dddddd;
          background-color: #fff;
          font-family: Graphik Trial;
          font-size: 18px;
          font-weight: 600;
          line-height: 27px;
          @media screen and (max-width: 425px) {
            width: 95%;
          }
        }
        button:hover {
          border: 1px solid #773dd3;
          border-radius: 5px;
          background-color: #773dd3;
          color: #fff;
        }
      }
    }
    &_executive {
      width: 1440px;
      margin: 0 auto;
      margin-top: 48px;
      padding: 32px 40px;
      border-radius: 20.04px;
      text-align: center;
      gap: 24px;
      box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.1);
      // margin-bottom: 80px;
      @media screen and (max-width: 1440px) {
        width: 90%;
      }
      @media screen and (max-width: 768px) {
        width: 95%;
        display: none;
      }
      @media screen and (max-width: 425px) {
        padding: 0;
      }

      &_text {
        margin: 0 auto;
        width: 95%;
        @media screen and (max-width: 768px) {
          width: 100%;
        }
      }
      h5 {
        font-family: "Graphik Trial";
        font-size: 32px;
        font-weight: 500;
        line-height: 48px;
        text-align: center;

        color: #333333;
      }
      p {
        font-family: "Graphik Trial";
        font-size: 18px;
        font-weight: 400;
        line-height: 27px;
        letter-spacing: 0em;
        text-align: center;
        color: #646464;
        margin: 24px 0;
        @media screen and (max-width: 425px) {
          font-size: 16px;
          line-height: 24px;
          margin-top: 12px;
          margin-bottom: 24px;
        }
      }

      &_image > img {
        width: 100%;
        height: 100%;
      }
      &_image {
        width: 100%;
        height: 488px;
        border-radius: 8px;
      }
    }
    &_executive2 {
      width: 1440px;
      margin:40px auto;
      // margin:  0;
      padding: 32px 40px;
      border-radius: 20.04px;
      text-align: center;
      gap: 24px;
      box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.1);
      // margin-bottom: 80px;
      @media screen and (max-width: 1440px) {
        width: 90%;
      }
      @media screen and (max-width: 768px) {
        width: 100%;
      padding: 20px 30px;

      }
      @media screen and (max-width: 430px) {
        padding: 10px 15px;

      }

      &_text {
        margin:  auto;
        width: 95%;
        @media screen and (max-width: 768px) {
          width: 100%;
        }
      }
      h5 {
        font-family: "Graphik Trial";
        font-size: 32px;
        font-weight: 500;
        line-height: 48px;
        text-align: center;

        color: #333333;
      }
      p {
        font-family: "Graphik Trial";
        font-size: 18px;
        font-weight: 400;
        line-height: 27px;
        letter-spacing: 0em;
        text-align: center;
        color: #646464;
        margin: 24px 0;
        @media screen and (max-width: 425px) {
          font-size: 16px;
          line-height: 24px;
          margin-top: 12px;
          margin-bottom: 24px;
        }
      }

      img > div {
        width: 100%;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  &_benefit {
    background-image: url(../images/book/benBg.svg);
    background-repeat: no-repeat;
    background-color: #773dd3;
    width: 1440px;
    margin: 120px auto;
    border-radius: 16px;
    padding: 80px;
    @media screen and (max-width: 1440px) {
      width: 90%;
      padding: 80px 40px;
    }
    @media screen and (max-width: 980px) {
      width: 90%;
    }
    @media screen and (max-width: 425px) {
      padding: 40px 10px;
      margin: 80px auto;
    }
    h5 {
      font-family: Graphik Trial;
      font-size: 48px;
      font-weight: 700;
      line-height: 52.8px;
      text-align: left;
      width: 781px;
      color: #fff;
      margin-bottom: 56px;
      @media screen and (max-width: 980px) {
        width: 90%;
      }
      @media screen and (max-width: 768px) {
        font-size: 42px;
      }
      @media screen and (max-width: 768px) {
        font-size: 36px;
        line-height: 39.6px;
        width: 95%;
        margin-bottom: 24px;
      }
    }
    &_grid {
      display: grid;
      grid-template-columns: auto auto auto;
      grid-template-rows: auto auto;
      grid-gap: 10px;
      margin: 0 auto;
      @media screen and (max-width: 768px) {
        display: block;
      }
      &_items {
        h6 {
          font-family: Graphik Trial;
          font-size: 24px;
          font-weight: 500;
          line-height: 26.4px;
          text-align: left;
          margin: 24px 0;
          color: #fff;
          @media screen and (max-width: 768px) {
            margin: 12px 0;
          }
        }
        p {
          font-family: Graphik Trial;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          text-align: left;
          margin-bottom: 32px;
          color: #fff;
          @media screen and (max-width: 768px) {
            margin-bottom: 28px;
          }
        }
      }
    }
  }
  &_box {
    width: 20%;
    padding: 24px;
    border-radius: 16px;
    gap: 8px;
    border: 1px solid rgba(221, 221, 221, 1);
    &_text {
      margin: auto;
      align-items: center;
      text-align: center;

      span {
        font-family: Graphik Trial;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        // text-align: center;
        color: #333333;
      }
      h6 {
        font-family: Graphik Trial;
        font-size: 32px;
        font-weight: 500;
        line-height: 38px;
        letter-spacing: 0em;
        // text-align: center;
        color: #333333;
        margin: 8px 0;
      }
    }
  }
  &_gallery {
    width: 1440px;
    margin: 0 auto;
    overflow: hidden;
    @media screen and (max-width: 1440px) {
      width: 100%;
    }
    &_text {
      width: 589px;
      margin: 0 auto;
      position: relative;
      @media screen and (max-width: 768px) {
        width: 90%;
      }
      @media screen and (max-width: 430px) {
        width: 95%;
      }
      h1 {
        font-family: Graphik Trial;
        font-size: 48px;
        font-weight: 600;
        line-height: 72px;
        letter-spacing: 0em;
        text-align: center;
        color: #333333;
      }
      p {
        font-family: Graphik Trial;
        font-size: 24px;
        font-weight: 400;
        line-height: 48px;
        letter-spacing: 0em;
        text-align: center;
        color: #646464;
        margin: 16px 0;
        @media screen and (max-width: 768px) {
          margin: 12px 0;
          line-height: 40px;
        }
        @media screen and (max-width: 430px) {
          margin: 8px 0;
          line-height: 29px;
        }
      }
      span {
        font-family: Graphik Trial;
        font-size: 24px;
        font-weight: 400;
        line-height: 36px;
        letter-spacing: 0em;
        text-align: center;
        color: #333333;
        @media screen and (max-width: 430px) {
          font-size: 12px;
        }
      }
      &_userFlex {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        padding: 18px 0;
        margin: 0 auto;
        background-color: #dccdf4;
        font-family: Graphik Trial;
        font-size: 24px;
        font-weight: 400;
        line-height: 36px;
        letter-spacing: 0em;
        text-align: center;
        color: #333333;
        border-radius: 16px;
        margin-bottom: 32px;
    
      }
    }
  }
  &_review {
    width: 1440px;
    padding: 80px 0;
    background-color: #fcf9ff;
    margin: 80px auto;
    @media screen and (max-width: 1440px) {
      width: 100%;
    }
    @media screen and (max-width: 768px) {
      padding: 32px 0;
      margin: 80px auto;
    }
    h4 {
      font-family: Graphik Trial;
      font-size: 48px;
      font-weight: 500;
      line-height: 52.8px;
      text-align: left;
      color: #333333;
      margin-bottom: 12px;
      @media screen and (max-width: 430px) {
        font-size: 32px;
        text-align: center;
      }
    }
    p {
      font-family: Graphik Trial;
      font-size: 18px;
      font-weight: 400;
      line-height: 27px;
      color: #646464;
      margin-bottom: 56px;
      @media screen and (max-width: 430px) {
        margin-bottom: 24px;
        text-align: center;
      }
    }
    &_innerCon {
      width: 95%;
      margin: auto;
    }
    &_items {
      max-width: 600px;
      padding: 48px 32px;
      gap: 48px;
      border-radius: 8px;
      border: 1px solid #d5d5d5;
      // margin-top: 56px;
      h5 {
        font-family: Graphik Trial;
        font-size: 18px;
        font-weight: 400;
        line-height: 27px;
        text-align: left;
        color: #333333;
        // margin-top: 112px;
        @media screen and (max-width: 430px) {
          font-size: 16px;
        }
      }
      &_flex {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-top: 32px;
        h6 {
          font-family: Graphik Trial;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          text-align: left;
        }
        span {
          font-family: Graphik Trial;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          color: #4f4f4f;
        }
      }
    }
  }
  &_community {
    width: 1440px;
    margin: 0 auto;
    padding: 80px 0;
    background-image: url(/src/images/book/Stroke.png);
    background-color: #773dd3;
    border-radius: 16px;

    @media screen and (max-width: 1440px) {
      width: 90%;
    }
    @media screen and (max-width: 430px) {
      padding: 60px 0;
    }
    &_content {
      width: 777px;
      margin: 0 auto;
      align-items: center;
      @media screen and (max-width: 768px) {
        width: 95%;
      }
      h6 {
        font-family: Graphik Trial;
        font-size: 40px;
        font-weight: 500;
        line-height: 48px;
        text-align: center;
        color: #fff;
        @media screen and (max-width: 430px) {
          font-size: 32px;
          line-height: 35px;
        }
      }
      p {
        font-family: graphik Trial;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        color: #fff;
        margin: 20px 0;
       
      }
      button {
        display: flex;

        margin: auto;
        font-family: Graphik Trial;
        font-size: 18px;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: center;
        padding: 17px 40px;
        background-color: #e7a300;
        color: #fff;
        border: none;
        border-radius: 8px;
      }
    }
  }

  &_line {
    position: absolute;
    top: 50px;
    right: 35%;
    z-index: -1;
    @media screen and (max-width: 768px) {
      right: 30%;
    }
  }
  &_line2 {
    position: absolute;
    top: 30%;
    right: 41%;
    @media screen and (max-width: 430px) {
      right: 25%;
      top: 25%;
    }
  }
  &_slider {
    display: flex;
    flex-wrap: wrap;
    // margin-right: -20px;
    margin-left: -20px;
    width: 100vw;
    margin: auto;
  }
&_imageCon {
    height: auto;
    overflow: hidden;
    background-color: white !important;
    position: relative;
    width: auto;
  }
  &_imageCon {
    height: auto;
    overflow: hidden;
    background-color: white !important;
    position: relative;
    width: auto;
    
  }
  &_image{
    width: 400px;
    height: 300px;
    border-radius: 8px;
    
  }
  &_slide {
    animation: scrollRight 30s linear infinite;
    animation-direction: reverse;
    display: flex;
    width: calc(200px * 90);
    overflow: hidden;
    gap: 10px;
  }
  &_slide2 {
    animation: scroll 20s linear infinite;
    display: flex;
    width: calc(200px * 90);
    overflow-x: hidden;
    gap: 10px;
    margin-bottom: 20px;
  }
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-350px * 7));
    }
  }

  @keyframes scrollRight {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-350px * 7));
    }
  }
}
.splide__arrows {
  position: absolute;
  bottom: -30px;
  right: 120px;
  @media screen and (max-width: 768px){
    right: 30px;
  }
}

.splide__pagination {
  position: absolute;
  bottom: 10px !important; // Adjust as needed
  right: 10px !important;
}