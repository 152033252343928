@mixin clearfix {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin disabled {
  color: #fff !important;
  border-color: #a0a0a0 !important;
  background-color: #a0a0a0 !important;
  cursor: not-allowed !important;
}

@mixin container {
  width: 85%;
  margin: auto;

  @include respond(tab-land) {
    width: 90%;
  }

  // @include respond(tab-port){
  //   width: 93%;
  // }
}

//MEDIA QUERY
/*
0 - 600px:     Phone
600 - 900px    Tablet portrait
900 - 1200px   Tablet landscape
1200 - 1800px  Big desktop

*/

/*
$breakpoint argument choices
- phone
- tab-port
- tab-land
- big-desktop

*/

@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    @media (max-width: 37.5em) {
      //600px
      @content;
    }
  }

  @if $breakpoint == tab-port {
    @media (max-width: 56.25em) {
      //900px
      @content;
    }
  }

  @if $breakpoint == tab-land {
    @media (max-width: 75em) {
      //1200px
      @content;
    }
  }

  @if $breakpoint == big-desktop {
    @media (min-width: 112.5em) {
      //1800px
      @content;
    }
  }
}
