/*
0 - 600px:     Phone
600 - 900px    Tablet portrait
900 - 1200px   Tablet landscape
1200 - 1800px  Big desktop

*/
/*
$breakpoint argument choices
- phone
- tab-port
- tab-land
- big-desktop

*/
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: "graphik";
  src: url("../fonts/graphik/Graphik-Light.otf") format("otf");
}
html {
  font-size: 100%;
  overflow-x: hidden;
  box-sizing: border-box;
  font-family: "graphik" !important;
  position: relative;
}
@media (max-width: 75em) {
  html {
    font-size: 87.5%;
  }
}
@media (max-width: 56.25em) {
  html {
    font-size: 62.5%;
  }
}
@media (max-width: 37.5em) {
  html {
    font-size: 50%;
  }
}
@media (min-width: 112.5em) {
  html {
    font-size: 100%;
  }
}

body {
  font-family: "graphik";
  position: relative;
}

.title {
  font-weight: bold;
  font-size: 4.375rem;
  letter-spacing: 0.01em;
  text-shadow: 2px 2px #d4dfff;
  color: #1f1035;
  -webkit-hyphens: auto;
          hyphens: auto;
  font-family: "graphik";
}

.muted {
  font-weight: normal;
  color: #575a65;
  line-height: 1.875rem;
  font-family: graphik;
}
@media (max-width: 56.25em) {
  .muted {
    line-height: 2.875rem;
  }
}
.muted-1 {
  font-size: 1.125rem;
}
@media screen and (max-width: 800px) {
  .muted-1 {
    font-size: 1.3rem;
  }
}
.muted-2 {
  font-size: 0.875rem;
}
@media screen and (max-width: 800px) {
  .muted-2 {
    font-size: 1.3rem;
  }
}
.muted-3 {
  font-size: 1rem;
}

.small {
  font-weight: normal;
  font-size: 0.75rem;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  margin-bottom: 0 !important;
  font-family: "graphik";
}
@media screen and (max-width: 800px) {
  .small {
    font-size: 1.2rem;
  }
}
.small-white {
  color: #ffffff;
}
.small-grey {
  color: #575a65;
}

.subheader {
  font-weight: bolder;
  font-size: 2.375rem;
  letter-spacing: 0.01em;
  font-family: "graphik";
}
.subheader-white {
  color: #ffffff;
}
.subheader-purple {
  color: #271048;
}

.name {
  color: #1f1035;
}
.name-1 {
  font-weight: bold;
  font-size: 1.375rem;
}
.name-2 {
  font-weight: 500;
  font-size: 1.5rem;
}

.blogtitle {
  font-size: 1rem;
  letter-spacing: 0.01em;
  color: #773dd3;
  font-family: "graphik";
}
@media screen and (max-width: 800px) {
  .blogtitle {
    font-size: 1rem;
  }
}

.opacity {
  opacity: 0 !important;
}

.apply-title {
  font-family: graphik;
  font-style: normal;
  font-weight: 500;
  font-size: 1.3rem;
  line-height: 65px;
  color: #1f1035;
}

@keyframes opac {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.team {
  font-size: 14px;
  line-height: 65px;
  color: #575a65;
}
@keyframes hvr-buzz {
  50% {
    transform: translateX(3px) rotate(2deg);
  }
  100% {
    transform: translateX(-3px) rotate(-2deg);
  }
}
@keyframes move-up {
  0% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
}
.u-padding-none {
  padding: 0;
}

.u-margin-none {
  margin: 0;
}

.mt {
  margin-top: 3rem;
}

.footer {
  margin: 70px 0 50px;
  padding: 0 120px;
  box-sizing: border-box;
}
.footer h3 {
  color: #1f1035;
  margin-bottom: 20px;
  font-size: 1.2rem;
}
.footer p, .footer a {
  color: #575a65;
  font-size: 12px;
  display: block;
  text-decoration: none;
  margin-bottom: 1rem;
  cursor: pointer;
}
@media (max-width: 1280px) {
  .footer {
    padding: 0 70px;
  }
}
@media (max-width: 1024px) {
  .footer {
    padding: 0 32px;
  }
}
@media (max-width: 600px) {
  .footer {
    padding: 0 16px;
  }
}
@media (min-width: 992px) {
  .footer_bodies {
    display: flex;
  }
}
.footer_bodies .section4 {
  margin-bottom: 40px;
}
@media (min-width: 992px) {
  .footer_bodies .section4 {
    width: 10%;
  }
}
.footer_bodies-innerflex {
  display: flex;
  margin-bottom: 30px;
}
@media (min-width: 992px) {
  .footer_bodies-innerflex {
    width: 45%;
  }
}
.footer_bodies-innerflex-1 {
  width: 50%;
}
.footer_copyright {
  margin-top: 20px;
  color: #575a65;
  display: flex;
}
.footer_copyright .copy {
  width: 50%;
}
.footer_copyright .terms {
  width: 50%;
  text-align: right;
}
@media (min-width: 500px) {
  .footer_copyright .terms span {
    margin-left: 30px;
  }
}
@media (max-width: 500px) {
  .footer_copyright .terms span:before {
    content: "\a";
    white-space: pre;
  }
}

.button {
  text-decoration: none;
  text-align: center;
  padding: 15px 25px;
  border-radius: 5px;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  border: none;
  background-color: transparent;
  transition: transform 0.5s ease;
}
.button:disabled {
  color: #fff !important;
  border-color: #a0a0a0 !important;
  background-color: #a0a0a0 !important;
  cursor: not-allowed !important;
}
.button:hover {
  transform: translateY(-0.3rem);
}
@media (max-width: 56.25em) {
  .button {
    padding: 0.9rem 2rem;
    font-size: 1.4rem;
  }
}
.button > img {
  margin-left: 15px;
  animation: hvr-buzz 1s linear infinite;
}
.button-bg {
  background-color: #773DD3;
  color: white;
}
.button-bg:hover {
  color: #ffffff;
}
.button-nobg {
  border: 1px solid #773dd3;
  border-radius: 5px;
  color: #773DD3;
}
.button-nobg:hover {
  color: #773dd3;
}

.value {
  background: #ffffff;
  border-radius: 10px;
  width: -moz-fit-content;
  width: fit-content;
  transition: all 0.3s ease-in;
}
.value:hover {
  transform: translateY(-0.5rem) scale(1.05);
}
.value__container {
  width: 85%;
  margin: auto;
  padding: 3rem 0;
}
@media (max-width: 75em) {
  .value__container {
    width: 90%;
  }
}
@media screen and (max-width: 800px) {
  .value__container {
    padding: 4rem 0;
  }
}
.value__container h2 {
  font-weight: 600;
  font-size: 38px;
  line-height: 50px;
  color: #f5f5f5;
}
.value__content {
  margin-top: 30px;
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.form__group {
  margin-bottom: 3rem;
  display: flex;
  flex-direction: column;
}
.form__group select option {
  background-color: #1f1035;
  padding: 1rem 0;
  display: block;
}
.form__label {
  font-size: 1.3rem;
  line-height: 65px;
  color: #575a65;
}
.form__input {
  display: inline-block;
  border: none;
  border-bottom: 1px solid #f5f6f8;
  background: transparent;
  color: #ffffff;
  font-size: 1.7rem;
}
.form__input:focus {
  outline: none;
}
.form__input:-webkit-autofill {
  -webkit-text-fill-color: #ffffff;
  box-shadow: 0 0 0px 1000px #1f1035 inset;
  -webkit-box-shadow: 0 0 0px 1000px #1f1035 inset;
}
.form__textarea {
  background: transparent;
  border: none;
  border-bottom: 1px solid #f5f6f8;
  color: #ffffff;
  font-size: 1.7rem;
  max-height: 100px;
  min-height: 100px;
}
.form__textarea:focus {
  outline: none;
}

.header {
  width: 100%;
  background-color: #ffffff;
  position: relative;
  z-index: 10000;
}
@media (min-width: 1920px) {
  .header {
    max-width: 1920px;
    margin: 0 auto;
  }
}
.header__container {
  width: 85%;
  margin: auto;
  padding: 1rem 0;
  display: flex;
  align-items: center;
}
@media (max-width: 75em) {
  .header__container {
    width: 90%;
  }
}
@media screen and (max-width: 800px) {
  .header__container {
    justify-content: space-between;
  }
}
.header__img__box {
  width: 150px;
  display: block;
}
.header__img__box img {
  width: 100%;
  height: auto;
}
.header__contact {
  margin-left: 10rem;
  align-self: center;
  display: flex;
  text-decoration: none;
  align-items: center;
  cursor: pointer;
}
@media screen and (max-width: 1550px) {
  .header__contact {
    margin-left: 6rem;
  }
}
@media screen and (max-width: 1000px) {
  .header__contact {
    margin-left: 4rem;
  }
}
@media screen and (max-width: 800px) {
  .header__contact {
    display: none;
  }
}
.header__contact span {
  margin-right: 0.8rem;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 25px;
  color: #773dd3;
}
.header__contact div {
  height: 40px;
  width: 40px;
}
.header__contact div img {
  width: 100%;
  height: 100%;
}
.header__hamburger {
  display: none;
  height: 35px;
  width: 35px;
  cursor: pointer;
  background: rgba(243, 239, 255, 0.5);
  border-radius: 3px;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 800px) {
  .header__hamburger {
    display: flex;
  }
}

.nav {
  position: relative;
  margin-left: auto;
  flex-grow: 1;
}
@media screen and (max-width: 800px) {
  .nav {
    display: none;
  }
}
.nav__list {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 1rem 0;
}
.nav__item:not(:last-child) {
  margin-right: 6rem;
}
@media screen and (max-width: 1550px) {
  .nav__item:not(:last-child) {
    margin-right: 4rem;
  }
}
@media screen and (max-width: 1000px) {
  .nav__item:not(:last-child) {
    margin-right: 2rem;
  }
}
.nav__link {
  display: inline-block;
  text-decoration: none;
  font-weight: 500;
  font-size: 1rem;
  line-height: 25px;
  color: #575a65;
}
.nav__link:hover {
  color: #773DD3;
}

.blog {
  position: relative;
  display: block;
  font-family: "graphik";
}
.blog__img {
  width: 100%;
  height: 100%;
}
.blog__img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.blog__content {
  position: absolute;
  bottom: -80px;
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 800px) {
  .blog__content {
    width: 100%;
    left: 0;
  }
}
.blog__content-container {
  padding: 1.5rem;
  border-radius: 6px;
  background: #ffffff;
  width: 90%;
  margin: auto;
}
.blog__content-container h6 {
  text-transform: uppercase;
}
.blog__content-container h3 {
  margin-top: 1rem;
  font-weight: 500;
}
.blog__content-container small {
  margin-top: 2rem;
  font-size: 12px;
  line-height: 22px;
  color: #575a65;
}

.subscribe__container {
  width: 30%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 56.25em) {
  .subscribe__container {
    width: 85%;
  }
}
.subscribe p {
  font-size: 1rem;
  color: #1f1035;
  letter-spacing: 0.01em;
  align-self: stretch;
}
.subscribe_email {
  border: 1px solid #f5f6f8;
  box-sizing: border-box;
  border-radius: 6px;
  width: 100%;
  height: 40px;
  padding: 0 10px;
  font-size: 1em;
  align-self: stretch;
}
.subscribe_email:focus {
  outline: none;
}
.subscribe__img {
  position: absolute;
  top: 8px;
  right: 20px;
  border: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #773dd3;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.sidebar {
  background-color: #2d3748;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100vw; /* viewport width */
  height: 100vh; /* viewport height */
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 100000;
  transition: background-image 0.8s cubic-bezier(0.86, 0, 0.07, 1);
}
@media screen and (min-width: 800px) {
  .sidebar {
    display: none;
  }
}
.sidebar__container {
  width: 90%;
  margin: auto;
  padding: 6rem 0;
}
.sidebar__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sidebar__cancel {
  background: rgba(151, 161, 184, 0.1);
  border-radius: 3px;
  width: 25px;
  height: 25px;
  display: flex;
  place-items: center;
  justify-content: center;
}
.sidebar__nav {
  margin-top: 10rem;
  position: relative;
}
.sidebar__items {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
}
.sidebar__item {
  padding-bottom: 4rem;
  margin-bottom: 5rem;
}
.sidebar__item:not(:last-child) {
  border-bottom: 1px solid #ffffff;
}
.sidebar__link:link, .sidebar__link:visited {
  font-family: "graphik";
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  text-decoration: none;
  color: #ffffff;
  display: block;
}
.sidebar__link:focus {
  color: #ffffff;
}
.sidebar__contact {
  background: #773dd3;
  border-radius: 5px;
  color: white;
}
.sidebar__contact button {
  width: 100%;
  border: none;
}

.scroll {
  position: fixed;
  bottom: 10px;
  right: 20px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  background-color: #773DD3;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: hvr-hang 1s linear infinite;
}
.scroll__arrow {
  width: 70%;
  height: auto;
  color: #fff;
}

.unavailable {
  width: 100%;
  margin-top: 25rem;
}
.unavailable__container {
  margin: auto;
  padding: 12rem 0 5rem 0;
  text-align: center;
}
.unavailable__container h1 {
  font-size: 5rem;
  color: #5726a6;
  font-weight: bold;
  letter-spacing: 1rem;
  margin-bottom: 2rem;
}
.unavailable__container p {
  font-size: 2.5rem;
}

.form-group {
  display: flex;
  flex-direction: column;
}
.form-label {
  font-family: graphik;
  font-size: 1.2rem;
  color: #575a65;
}
.form-input {
  border: 1px solid #EBEBEB;
  border-radius: 3px;
  padding: 1rem;
  font-size: 1.5rem;
  font-family: graphik;
  display: block;
}
.form-input:focus {
  outline: none;
}
.form-input:-webkit-autofill {
  -webkit-text-fill-color: black;
  box-shadow: 0 0 0px 1000px #ffffff inset;
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
}
.form-valid {
  border: 1px solid green;
}
.form-invalid {
  border: 1px solid red !important;
  background-color: #fda49a;
}

.popup {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  background-color: rgba(240, 240, 240, 0.8);
}
.popup__container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.popup__box {
  background-color: #ffffff;
  width: 50%;
  margin: auto;
  text-align: center;
  padding: 10rem 0;
  position: relative;
}
@media (max-width: 75em) {
  .popup__box {
    width: 70%;
  }
}
@media (max-width: 56.25em) {
  .popup__box {
    width: 95%;
  }
}
.popup__content {
  margin-top: 3rem;
  font-family: "Montserrat", sans-serif;
}
@media screen and (max-width: 600px) {
  .popup__content {
    margin-top: 1rem;
  }
}
.popup__content h6:first-of-type,
.popup__content p {
  font-size: 1.125rem;
  line-height: 22px;
  letter-spacing: 0.2em;
  color: #666666;
  text-transform: uppercase;
}
.popup__content h6:last-of-type {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 2.5rem;
  line-height: 49px;
  letter-spacing: -0.05em;
  margin-bottom: 1rem;
  color: #783dd3;
}
.popup__content p {
  width: 50%;
  margin: auto;
  line-height: 34px;
  margin-bottom: 2rem;
}
.popup__content a {
  width: 30%;
  margin: auto;
}
@media (max-width: 56.25em) {
  .popup__content a {
    width: 50%;
  }
}
.popup__cancel {
  position: absolute;
  top: 1.875rem;
  right: 1.875rem;
  height: 1.875rem;
  width: 1.875rem;
  border-radius: 50%;
  background-color: #773DD3;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.popup__cancel span {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 41px;
  text-align: center;
  font-variant: small-caps;
  color: #ffffff;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 20px;
  height: 64px;
  margin: 8px;
  border: 8px solid #773DD3;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #773DD3 transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.space {
  position: fixed;
  z-index: 1000000000;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(31, 16, 53, 0.1);
  -webkit-backdrop-filter: blur(14px);
          backdrop-filter: blur(14px);
  width: 100vw;
  height: 100%;
  overflow-y: auto;
}
.space__box {
  width: 100%;
  display: flex;
  justify-content: center;
}
.space__container {
  background-color: white;
  position: relative;
  margin: 5rem auto 5rem auto;
  width: 60%;
  height: 100%;
  overflow-y: auto;
  border-radius: 5px;
}
@media (max-width: 800px) {
  .space__container {
    width: 80%;
  }
}
@media (max-width: 550px) {
  .space__container {
    width: 95%;
  }
}
.space__container h2 {
  font-family: graphik;
  font-style: normal;
  font-weight: bold;
  font-size: 2.05rem;
  color: #1f1035;
}
.space__content {
  padding: 3rem;
}
.space__form {
  width: 100%;
}
.space__form-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
}
@media (max-width: 400px) {
  .space__form-container {
    grid-template-columns: repeat(1, 1fr);
  }
}
.space__form-container:not(:last-child) {
  margin-bottom: 1rem;
  padding-bottom: 3rem;
  border-bottom: 1px solid #ebebeb;
}
.space__btns {
  margin-top: 2rem;
  display: flex;
}
.space__btns > button:first-of-type {
  margin-right: 2rem;
}

.svg-circle1 {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: rgba(119, 61, 211, 0.1);
  position: relative;
}
.svg-circle1 img {
  position: absolute;
  left: 30%;
  top: 30%;
}

.landing {
  box-sizing: border-box;
}
.landing__blog {
  margin-top: 10rem !important;
}
.landing__blog-header {
  margin-bottom: 4rem;
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 450px) {
  .landing__blog-header > a {
    display: none;
  }
}
.landing__partners {
  width: 100%;
  margin-bottom: 8rem;
  margin-top: 3rem;
}
.landing__partners-box {
  width: 85%;
  margin: auto;
}
@media (max-width: 75em) {
  .landing__partners-box {
    width: 90%;
  }
}
.landing__partners-box p {
  color: #773DD3 !important;
}
.landing__partners-text {
  text-align: center;
}
.landing__partners-container {
  display: flex;
  justify-content: space-between;
  margin-top: 4rem;
}
@media screen and (max-width: 930px) {
  .landing__partners-container {
    display: none;
  }
}
@media screen and (max-width: 848px) {
  .landing__partners-container {
    grid-template-columns: repeat(3, 1fr);
    row-gap: 4rem;
  }
}
@media screen and (max-width: 600px) {
  .landing__partners-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 320px) {
  .landing__partners-container {
    grid-template-columns: repeat(1, 95%);
    row-gap: 6rem;
  }
}
.landing__partners-img {
  width: 100%;
  height: auto;
  align-self: center;
  display: flex;
}
@media screen and (max-width: 848px) {
  .landing__partners-img {
    justify-content: center;
  }
}
@media screen and (max-width: 320px) {
  .landing__partners-img {
    grid-column: 1/-1 !important;
  }
}
.landing__partners-img:not(:last-child) {
  margin-right: 1rem;
}
@media screen and (max-width: 930px) {
  .landing__partners-img:not(:last-child) {
    margin-right: 0;
  }
}
@media screeN and (max-width: 600px) {
  .landing__partners-img:nth-child(3) {
    grid-row: 2/3;
  }
}
@media screen and (max-width: 848px) {
  .landing__partners-img:nth-child(4) {
    grid-column: 1/3;
  }
}
@media screen and (max-width: 600px) {
  .landing__partners-img:nth-child(4) {
    width: 100%;
    grid-column: 1/-1;
  }
  .landing__partners-img:nth-child(4) img {
    width: 100%;
    height: auto;
  }
}
.landing__partners-img:last-child {
  flex-direction: column !important;
  align-items: center !important;
}
@media screen and (max-width: 848px) {
  .landing__partners-img:last-child {
    grid-column: 3/-1;
  }
}
@media screen and (max-width: 600px) {
  .landing__partners-img:last-child {
    grid-row: 2/3;
    grid-column: 2/3;
  }
}
@media screen and (max-width: 320px) {
  .landing__partners-img:last-child {
    grid-row: 1/2;
  }
}
.landing_pattern {
  position: relative;
}
.landing_hero {
  text-align: center;
  margin: 100px auto 70px auto;
  width: 95%;
}
@media (min-width: 992px) {
  .landing_hero {
    max-width: 800px;
    display: block;
    margin: 100px auto 70px;
  }
}
.landing_hero h6 {
  font-size: 1rem;
  font-weight: 600;
}
.landing_hero h6:first-child {
  color: #773DD3;
}
.landing_hero h6:last-child {
  font-size: 1.1rem;
}
@media (max-width: 600px) {
  .landing_hero h6:last-child {
    font-size: 2rem;
  }
}
@media (max-width: 600px) {
  .landing_hero h6 {
    font-size: 2rem;
  }
}
.landing_hero p {
  font-size: 1rem;
}
.landing_hero p:first-child {
  color: #773DD3;
}
.landing_hero p:last-child {
  font-size: 1.1rem;
}
@media (max-width: 600px) {
  .landing_hero p:last-child {
    font-size: 2rem;
  }
}
@media (max-width: 600px) {
  .landing_hero p {
    font-size: 2rem;
  }
}
.landing_hero h1 {
  color: #1f1035;
  font-size: 3.5rem;
  font-weight: 700;
}
@media (max-width: 600px) {
  .landing_hero h1 {
    font-size: 3rem;
  }
}
.landing_hero h1 span {
  text-shadow: 2px 2px #d4dfff;
}
.landing_hero .purple-btn {
  color: #ffffff;
  background: #773DD3;
  border: 1px solid #773DD3;
  font-size: 1rem;
  padding: 10px 25px;
  margin: 20px 10px;
  border-radius: 5px;
}
@media (max-width: 600px) {
  .landing_hero .purple-btn {
    font-size: 2rem;
  }
}
.landing_hero .whitebg-btn {
  color: #773DD3;
  background: #ffffff;
  border: 2px solid #773DD3;
  font-size: 1rem;
  padding: 10px 25px;
  margin: 20px 10px;
  border-radius: 5px;
}
@media (max-width: 600px) {
  .landing_hero .whitebg-btn {
    font-size: 2rem;
  }
}
.landing_hero .pattern_1 {
  position: absolute;
  bottom: -70px;
  right: 0;
  z-index: 0;
}
@media (max-width: 700px) {
  .landing_hero .pattern_1 {
    width: 100%;
  }
}
.landing_hero .pattern_2 {
  position: absolute;
  bottom: -70px;
  z-index: 0;
  left: 0;
}
@media (max-width: 700px) {
  .landing_hero .pattern_2 {
    display: none;
  }
}
.landing_hero_btn {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  position: relative;
  z-index: 100;
}
.landing .nextbigthing {
  background: url("../images/nextbigthing.png");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 70px 0;
  box-sizing: border-box;
  margin-bottom: 10rem;
}
@media (max-width: 700px) {
  .landing .nextbigthing {
    background: url("../images/nextbigthing2.png");
    background-size: cover;
  }
}
.landing_wwd {
  background-color: rgba(243, 239, 255, 0.3);
  padding: 70px 0;
  box-sizing: border-box;
}
.landing_wwd-textpart p {
  font-size: 1rem;
}
@media (max-width: 600px) {
  .landing_wwd-textpart p {
    font-size: 2rem;
  }
}
.landing_wwd-textpart p:first-child {
  color: #773DD3;
}
.landing_wwd-textpart p:last-child {
  font-size: 1.1rem;
}
@media (max-width: 600px) {
  .landing_wwd-textpart p:last-child {
    font-size: 2rem;
  }
}
.landing_wwd-textpart h1 {
  color: #1f1035;
  font-size: 2.5em;
  margin-bottom: 20px;
  font-weight: bold;
}
@media (max-width: 600px) {
  .landing_wwd-textpart h1 {
    font-size: 3.7rem;
  }
}
.landing_wwd-textpart h1 span {
  text-shadow: 2px 2px #d4dfff;
}
.landing_wwd-columns {
  width: 90%;
  margin: auto;
}
@media (min-width: 1940px) {
  .landing_wwd-columns {
    width: 85%;
  }
}
@media (min-width: 450px) {
  .landing_wwd-columns {
    display: flex;
  }
}
.landing_wwd-columns .section1 {
  width: 33%;
  padding: 20px;
}
@media (max-width: 450px) {
  .landing_wwd-columns .section1 {
    width: 100%;
    padding: 10px 20px;
  }
}
.landing_wwd-four h3 {
  color: #1f1035;
  margin: 20px 0 10px;
  font-size: 1.4em;
  font-weight: 700;
}
@media (max-width: 600px) {
  .landing_wwd-four h3 {
    font-size: 2.5rem;
  }
}
.landing_wwd-four p {
  font-size: 1rem;
  color: #575a65;
}
@media (max-width: 600px) {
  .landing_wwd-four p {
    font-size: 2rem;
  }
}
.landing_wwd-four p:last-child {
  color: #773DD3;
  margin-bottom: 30px;
}
@media (min-width: 450px) {
  .landing_wwd-column1 {
    display: flex;
  }
}
.landing_wwd-column1 .section2 a {
  width: 50%;
}
@media (max-width: 600px) {
  .landing_wwd-column1 .section2 a {
    width: 100%;
  }
}
@media (min-width: 555px) {
  .landing_wwd-column1 .section2 {
    width: 50%;
  }
}
.landing_wwd-column1 .section2 .business {
  width: 80%;
  display: block;
  margin: auto;
}
@media (max-width: 800px) {
  .landing_wwd-column1 .section2 .business {
    margin: 100px auto;
  }
}
@media (max-width: 450px) {
  .landing_wwd-column1 .section2 .business {
    margin: auto;
  }
}
.landing_wwd-column1 .section3 {
  padding: 20px;
}
.landing_wwd-column1 h1 {
  color: #1f1035;
  font-size: 2.4em;
  margin: 50px 10px 20px;
  font-weight: 700;
}
@media (max-width: 700px) {
  .landing_wwd-column1 h1 {
    color: white;
  }
}
@media (max-width: 600px) {
  .landing_wwd-column1 h1 {
    font-size: 3.5rem;
  }
}
.landing_wwd-column1 p {
  font-size: 1rem;
  color: #575a65;
  padding: 10px 10px 0;
}
@media (max-width: 700px) {
  .landing_wwd-column1 p {
    color: white;
  }
}
@media (max-width: 600px) {
  .landing_wwd-column1 p {
    font-size: 2rem;
  }
}
.landing_wwd-column1 button {
  color: #ffffff;
  background: #773DD3;
  border: 1px solid #773DD3;
  font-size: 1rem;
  padding: 10px 55px 10px 25px;
  margin: 20px 10px;
  border-radius: 5px;
  position: relative;
}
@media (max-width: 600px) {
  .landing_wwd-column1 button {
    font-size: 2rem;
  }
}
.landing_wwd-column1 button img {
  width: 23px;
  position: absolute;
  top: 10px;
  right: 10px;
}
.landing_featured {
  padding: 100px 0;
  box-sizing: border-box;
}
.landing_featured-text p {
  font-size: 1rem;
  color: #575a65;
}
@media (max-width: 600px) {
  .landing_featured-text p {
    font-size: 2rem;
  }
}
.landing_featured-text h1 {
  color: #1f1035;
  font-size: 2.4em;
  margin-bottom: 40px;
}
@media (max-width: 600px) {
  .landing_featured-text h1 {
    font-size: 3.5rem;
  }
}
.landing_featured-logos {
  display: grid;
  grid-row-gap: 30px;
  grid-template-columns: auto auto auto auto auto;
}
@media (max-width: 769px) {
  .landing_featured-logos {
    grid-template-columns: auto auto auto;
  }
}
@media (max-width: 450px) {
  .landing_featured-logos {
    grid-template-columns: auto auto;
  }
  .landing_featured-logos img {
    display: block;
    margin: auto;
  }
}
.landing_featured-logos img {
  width: 60%;
}
.landing_news {
  margin: 100px 0;
}
.landing_news-heading {
  display: flex;
  margin-bottom: 40px;
}
.landing_news-heading h1 {
  margin-top: 30px;
}
.landing_news-heading .blog-btn {
  margin-left: auto;
  order: 2;
}
.landing_news-heading button {
  color: #ffffff;
  background: #773DD3;
  border: 1px solid #773DD3;
  font-size: 1rem;
  padding: 10px 55px 10px 25px;
  margin: 20px 10px;
  border-radius: 5px;
  position: relative;
}
@media (max-width: 600px) {
  .landing_news-heading button {
    font-size: 2rem;
  }
}
.landing_news-heading button img {
  width: 23px;
  position: absolute;
  top: 10px;
  right: 10px;
}
.landing_news-cards {
  display: grid;
  grid-row-gap: 30px;
  grid-template-columns: auto auto auto;
}
@media (max-width: 992px) {
  .landing_news-cards {
    grid-template-columns: auto auto;
  }
}
@media (max-width: 570px) {
  .landing_news-cards {
    grid-template-columns: auto;
  }
}
.landing_news-cards-boxes {
  height: 250px;
  width: 95%;
  border-radius: 10px;
  background: gray;
  display: block;
  margin: 0 auto 50px;
  position: relative;
}
@media (max-width: 570px) {
  .landing_news-cards-boxes {
    width: 100%;
  }
}
.landing_news-cards-boxes img {
  width: 100%;
  height: auto;
}
.landing_news-cards-boxes .small-box {
  height: 105px;
  position: absolute;
  width: 80%;
  border-radius: 10px;
  bottom: -10px;
  background-color: white;
  box-shadow: 0px 4px 14px rgba(151, 161, 184, 0.1);
  z-index: 300;
  padding: 10px;
  left: 20px;
  right: 20px;
}
.landing_news-cards-boxes .small-box h4 {
  font-size: 1.4em;
}
@media (min-width: 900px) {
  .landing_news-cards-boxes .small-box h4 {
    font-size: 1rem;
  }
}
@media (max-width: 600px) {
  .landing_news-cards-boxes .small-box h4 {
    font-size: 2.2rem;
  }
}
.landing_news-cards-boxes .small-box p {
  color: #575a65;
  font-size: 1rem;
}
@media (min-width: 900px) {
  .landing_news-cards-boxes .small-box p {
    font-size: 1rem;
  }
}
@media (max-width: 600px) {
  .landing_news-cards-boxes .small-box p {
    font-size: 2rem;
  }
}
.landing_subscription {
  width: 500px;
  display: block;
  margin: auto;
  box-sizing: border-box;
}
@media (max-width: 550px) {
  .landing_subscription {
    width: 90%;
  }
}
.landing_subscription p {
  font-size: 1rem;
}
@media (max-width: 600px) {
  .landing_subscription p {
    font-size: 1.8rem;
  }
}
.landing_subscription input {
  width: 100%;
  height: 40px;
  font-size: 1rem;
  border: 1px solid #f5f6f8;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 0 15px;
}
@media (max-width: 600px) {
  .landing_subscription input {
    font-size: 2rem;
  }
}
.landing_subscription img {
  position: absolute;
  right: 20px;
  width: 30px;
  top: 5px;
}

/* Hang */
@keyframes hvr-hang {
  0% {
    transform: translateY(8px);
  }
  50% {
    transform: translateY(4px);
  }
  100% {
    transform: translateY(8px);
  }
}
@keyframes hvr-hang-sink {
  100% {
    transform: translateY(8px);
  }
}
.hvr-hang {
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  animation-name: hvr-hang-sink, hvr-hang;
  animation-duration: 0.3s, 1.5s;
  animation-delay: 0s, 0.3s;
  animation-timing-function: ease-out, ease-in-out;
  animation-iteration-count: 1, infinite;
  animation-fill-mode: forwards;
  animation-direction: normal, alternate;
}

/* Buzz */
@keyframes hvr-buzz {
  50% {
    transform: translateX(3px) rotate(2deg);
  }
  100% {
    transform: translateX(-3px) rotate(-2deg);
  }
}
.hvr-buzz {
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  animation-name: hvr-buzz;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

/* Bounce In */
.hvr-bounce-in {
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition-duration: 0.5s;
}

.hvr-bounce-in:hover,
.hvr-bounce-in:focus,
.hvr-bounce-in:active {
  transform: scale(1.2);
  transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
}

/* Grow */
.hvr-grow {
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition-duration: 0.3s;
  transition-property: transform;
}

.hvr-grow:hover,
.hvr-grow:focus,
.hvr-grow:active {
  transform: scale(1.1);
}

/* Wobble Horizontal */
@keyframes hvr-wobble-horizontal {
  16.65% {
    transform: translateX(8px);
  }
  33.3% {
    transform: translateX(-6px);
  }
  49.95% {
    transform: translateX(4px);
  }
  66.6% {
    transform: translateX(-2px);
  }
  83.25% {
    transform: translateX(1px);
  }
  100% {
    transform: translateX(0);
  }
}
.hvr-wobble-horizontal {
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}

.hvr-wobble-horizontal:hover,
.hvr-wobble-horizontal:focus,
.hvr-wobble-horizontal:active {
  animation-name: hvr-wobble-horizontal;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}

.landing__partners-img {
  display: flex !important;
  justify-content: center !important;
  width: 30% !important;
}

.landing__partners-img img {
  display: block;
  width: auto;
  height: auto;
}

.landing__partners-wrapper {
  display: none;
  margin-top: 30px !important;
}
@media screen and (max-width: 930px) {
  .landing__partners-wrapper {
    display: inline;
  }
}

.innovation_hero {
  background-image: url(/src/images/book/Hero.png);
  background-repeat: no-repeat;
  padding: 200px 0;
  margin: 0 auto;
  width: 1440px;
}
@media screen and (max-width: 1440px) {
  .innovation_hero {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .innovation_hero {
    padding: 130px 0;
    background-image: url(/src/images/book/joy-mobile.png);
    background-size: cover;
  }
}
@media screen and (max-width: 430px) {
  .innovation_hero {
    padding: 57px 0;
  }
}
.innovation_hero_text {
  width: 722px;
  text-align: center;
  margin: auto;
}
@media screen and (max-width: 768px) {
  .innovation_hero_text {
    width: 90%;
  }
}
@media screen and (max-width: 430px) {
  .innovation_hero_text {
    width: 95%;
  }
}
.innovation_hero_text h4 {
  font-family: Graphik Trial;
  font-size: 56px;
  font-weight: 600;
  line-height: 67px;
  color: white;
}
@media screen and (max-width: 768px) {
  .innovation_hero_text h4 {
    font-size: 50px;
    line-height: 54px;
  }
}
@media screen and (max-width: 430px) {
  .innovation_hero_text h4 {
    font-size: 40px;
    line-height: 44px;
  }
}
.innovation_hero_text span {
  color: #d4baff;
  text-decoration: underline;
}
.innovation_hero_text button {
  padding: 17px 53px;
  background-color: #773dd3;
  border-radius: 8px;
  font-family: Graphik Trial;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  border: none;
  color: white;
  margin-top: 32px;
}
@media screen and (max-width: 768px) {
  .innovation_hero_text button {
    margin-top: 32px;
  }
}
.innovation_offer {
  background-color: #fcf9ff;
  padding: 80px 0;
}
@media screen and (max-width: 768px) {
  .innovation_offer {
    padding: 40px 0;
  }
}
.innovation_offer_wrapper2 {
  display: none;
}
@media screen and (max-width: 768px) {
  .innovation_offer_wrapper2 {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}
.innovation_offer_wrapper2_btn {
  display: flex;
  width: 90%;
  justify-content: center;
  margin: 0 auto;
}
.innovation_offer_wrapper2 button {
  width: 290px;
  border-radius: 8px;
  padding: 15px 0px;
  border: 1px solid #dddddd;
  color: #fff;
  font-family: Graphik Trial;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  background-color: #773dd3;
}
@media screen and (max-width: 425px) {
  .innovation_offer_wrapper2 button {
    width: 95%;
  }
}
.innovation_offer_wrapper {
  width: 1440px;
  margin: 0 auto;
}
@media screen and (max-width: 1440px) {
  .innovation_offer_wrapper {
    width: 100%;
  }
}
.innovation_offer_wrapper_text {
  text-align: center;
  margin: 0 auto;
  position: relative;
}
.innovation_offer_wrapper_text h5 {
  font-family: Graphik Trial;
  font-size: 48px;
  font-weight: 600;
  line-height: 72px;
  color: #333333;
  margin-bottom: 16px;
  position: relative;
  z-index: 1;
}
@media screen and (max-width: 768px) {
  .innovation_offer_wrapper_text h5 {
    font-size: 40px;
    line-height: 65px;
  }
}
.innovation_offer_wrapper_text p {
  font-family: Graphik Trial;
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  color: #646464;
  width: 842px;
  margin: 0 auto;
  margin-bottom: 32px;
}
@media screen and (max-width: 768px) {
  .innovation_offer_wrapper_text p {
    width: 95%;
    font-size: 18px;
  }
}
.innovation_offer_wrapper_btnFlex {
  width: 80%;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  gap: 25px;
}
@media screen and (max-width: 1024px) {
  .innovation_offer_wrapper_btnFlex {
    width: 90%;
  }
}
@media screen and (max-width: 768px) {
  .innovation_offer_wrapper_btnFlex {
    display: none;
  }
}
.innovation_offer_wrapper_btnFlex button {
  width: 290px;
  border-radius: 8px;
  padding: 15px 0px;
  border: 1px solid #dddddd;
  background-color: #fff;
  font-family: Graphik Trial;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
}
@media screen and (max-width: 425px) {
  .innovation_offer_wrapper_btnFlex button {
    width: 95%;
  }
}
.innovation_offer_wrapper_btnFlex button:hover {
  border: 1px solid #773dd3;
  border-radius: 5px;
  background-color: #773dd3;
  color: #fff;
}
.innovation_offer_executive {
  width: 1440px;
  margin: 0 auto;
  margin-top: 48px;
  padding: 32px 40px;
  border-radius: 20.04px;
  text-align: center;
  gap: 24px;
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.1);
}
@media screen and (max-width: 1440px) {
  .innovation_offer_executive {
    width: 90%;
  }
}
@media screen and (max-width: 768px) {
  .innovation_offer_executive {
    width: 95%;
    display: none;
  }
}
@media screen and (max-width: 425px) {
  .innovation_offer_executive {
    padding: 0;
  }
}
.innovation_offer_executive_text {
  margin: 0 auto;
  width: 95%;
}
@media screen and (max-width: 768px) {
  .innovation_offer_executive_text {
    width: 100%;
  }
}
.innovation_offer_executive h5 {
  font-family: "Graphik Trial";
  font-size: 32px;
  font-weight: 500;
  line-height: 48px;
  text-align: center;
  color: #333333;
}
.innovation_offer_executive p {
  font-family: "Graphik Trial";
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
  color: #646464;
  margin: 24px 0;
}
@media screen and (max-width: 425px) {
  .innovation_offer_executive p {
    font-size: 16px;
    line-height: 24px;
    margin-top: 12px;
    margin-bottom: 24px;
  }
}
.innovation_offer_executive_image > img {
  width: 100%;
  height: 100%;
}
.innovation_offer_executive_image {
  width: 100%;
  height: 488px;
  border-radius: 8px;
}
.innovation_offer_executive2 {
  width: 1440px;
  margin: 40px auto;
  padding: 32px 40px;
  border-radius: 20.04px;
  text-align: center;
  gap: 24px;
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.1);
}
@media screen and (max-width: 1440px) {
  .innovation_offer_executive2 {
    width: 90%;
  }
}
@media screen and (max-width: 768px) {
  .innovation_offer_executive2 {
    width: 100%;
    padding: 20px 30px;
  }
}
@media screen and (max-width: 430px) {
  .innovation_offer_executive2 {
    padding: 10px 15px;
  }
}
.innovation_offer_executive2_text {
  margin: auto;
  width: 95%;
}
@media screen and (max-width: 768px) {
  .innovation_offer_executive2_text {
    width: 100%;
  }
}
.innovation_offer_executive2 h5 {
  font-family: "Graphik Trial";
  font-size: 32px;
  font-weight: 500;
  line-height: 48px;
  text-align: center;
  color: #333333;
}
.innovation_offer_executive2 p {
  font-family: "Graphik Trial";
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
  color: #646464;
  margin: 24px 0;
}
@media screen and (max-width: 425px) {
  .innovation_offer_executive2 p {
    font-size: 16px;
    line-height: 24px;
    margin-top: 12px;
    margin-bottom: 24px;
  }
}
.innovation_offer_executive2 img > div {
  width: 100%;
}
.innovation_offer_executive2 img {
  width: 100%;
  height: 100%;
}
.innovation_benefit {
  background-image: url(../images/book/benBg.svg);
  background-repeat: no-repeat;
  background-color: #773dd3;
  width: 1440px;
  margin: 120px auto;
  border-radius: 16px;
  padding: 80px;
}
@media screen and (max-width: 1440px) {
  .innovation_benefit {
    width: 90%;
    padding: 80px 40px;
  }
}
@media screen and (max-width: 980px) {
  .innovation_benefit {
    width: 90%;
  }
}
@media screen and (max-width: 425px) {
  .innovation_benefit {
    padding: 40px 10px;
    margin: 80px auto;
  }
}
.innovation_benefit h5 {
  font-family: Graphik Trial;
  font-size: 48px;
  font-weight: 700;
  line-height: 52.8px;
  text-align: left;
  width: 781px;
  color: #fff;
  margin-bottom: 56px;
}
@media screen and (max-width: 980px) {
  .innovation_benefit h5 {
    width: 90%;
  }
}
@media screen and (max-width: 768px) {
  .innovation_benefit h5 {
    font-size: 42px;
  }
}
@media screen and (max-width: 768px) {
  .innovation_benefit h5 {
    font-size: 36px;
    line-height: 39.6px;
    width: 95%;
    margin-bottom: 24px;
  }
}
.innovation_benefit_grid {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto auto;
  grid-gap: 10px;
  margin: 0 auto;
}
@media screen and (max-width: 768px) {
  .innovation_benefit_grid {
    display: block;
  }
}
.innovation_benefit_grid_items h6 {
  font-family: Graphik Trial;
  font-size: 24px;
  font-weight: 500;
  line-height: 26.4px;
  text-align: left;
  margin: 24px 0;
  color: #fff;
}
@media screen and (max-width: 768px) {
  .innovation_benefit_grid_items h6 {
    margin: 12px 0;
  }
}
.innovation_benefit_grid_items p {
  font-family: Graphik Trial;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  margin-bottom: 32px;
  color: #fff;
}
@media screen and (max-width: 768px) {
  .innovation_benefit_grid_items p {
    margin-bottom: 28px;
  }
}
.innovation_box {
  width: 20%;
  padding: 24px;
  border-radius: 16px;
  gap: 8px;
  border: 1px solid rgb(221, 221, 221);
}
.innovation_box_text {
  margin: auto;
  align-items: center;
  text-align: center;
}
.innovation_box_text span {
  font-family: Graphik Trial;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  color: #333333;
}
.innovation_box_text h6 {
  font-family: Graphik Trial;
  font-size: 32px;
  font-weight: 500;
  line-height: 38px;
  letter-spacing: 0em;
  color: #333333;
  margin: 8px 0;
}
.innovation_gallery {
  width: 1440px;
  margin: 0 auto;
  overflow: hidden;
}
@media screen and (max-width: 1440px) {
  .innovation_gallery {
    width: 100%;
  }
}
.innovation_gallery_text {
  width: 589px;
  margin: 0 auto;
  position: relative;
}
@media screen and (max-width: 768px) {
  .innovation_gallery_text {
    width: 90%;
  }
}
@media screen and (max-width: 430px) {
  .innovation_gallery_text {
    width: 95%;
  }
}
.innovation_gallery_text h1 {
  font-family: Graphik Trial;
  font-size: 48px;
  font-weight: 600;
  line-height: 72px;
  letter-spacing: 0em;
  text-align: center;
  color: #333333;
}
.innovation_gallery_text p {
  font-family: Graphik Trial;
  font-size: 24px;
  font-weight: 400;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: center;
  color: #646464;
  margin: 16px 0;
}
@media screen and (max-width: 768px) {
  .innovation_gallery_text p {
    margin: 12px 0;
    line-height: 40px;
  }
}
@media screen and (max-width: 430px) {
  .innovation_gallery_text p {
    margin: 8px 0;
    line-height: 29px;
  }
}
.innovation_gallery_text span {
  font-family: Graphik Trial;
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: center;
  color: #333333;
}
@media screen and (max-width: 430px) {
  .innovation_gallery_text span {
    font-size: 12px;
  }
}
.innovation_gallery_text_userFlex {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 18px 0;
  margin: 0 auto;
  background-color: #dccdf4;
  font-family: Graphik Trial;
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: center;
  color: #333333;
  border-radius: 16px;
  margin-bottom: 32px;
}
.innovation_review {
  width: 1440px;
  padding: 80px 0;
  background-color: #fcf9ff;
  margin: 80px auto;
}
@media screen and (max-width: 1440px) {
  .innovation_review {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .innovation_review {
    padding: 32px 0;
    margin: 80px auto;
  }
}
.innovation_review h4 {
  font-family: Graphik Trial;
  font-size: 48px;
  font-weight: 500;
  line-height: 52.8px;
  text-align: left;
  color: #333333;
  margin-bottom: 12px;
}
@media screen and (max-width: 430px) {
  .innovation_review h4 {
    font-size: 32px;
    text-align: center;
  }
}
.innovation_review p {
  font-family: Graphik Trial;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  color: #646464;
  margin-bottom: 56px;
}
@media screen and (max-width: 430px) {
  .innovation_review p {
    margin-bottom: 24px;
    text-align: center;
  }
}
.innovation_review_innerCon {
  width: 95%;
  margin: auto;
}
.innovation_review_items {
  max-width: 600px;
  padding: 48px 32px;
  gap: 48px;
  border-radius: 8px;
  border: 1px solid #d5d5d5;
}
.innovation_review_items h5 {
  font-family: Graphik Trial;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: left;
  color: #333333;
}
@media screen and (max-width: 430px) {
  .innovation_review_items h5 {
    font-size: 16px;
  }
}
.innovation_review_items_flex {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 32px;
}
.innovation_review_items_flex h6 {
  font-family: Graphik Trial;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
}
.innovation_review_items_flex span {
  font-family: Graphik Trial;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #4f4f4f;
}
.innovation_community {
  width: 1440px;
  margin: 0 auto;
  padding: 80px 0;
  background-image: url(/src/images/book/Stroke.png);
  background-color: #773dd3;
  border-radius: 16px;
}
@media screen and (max-width: 1440px) {
  .innovation_community {
    width: 90%;
  }
}
@media screen and (max-width: 430px) {
  .innovation_community {
    padding: 60px 0;
  }
}
.innovation_community_content {
  width: 777px;
  margin: 0 auto;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .innovation_community_content {
    width: 95%;
  }
}
.innovation_community_content h6 {
  font-family: Graphik Trial;
  font-size: 40px;
  font-weight: 500;
  line-height: 48px;
  text-align: center;
  color: #fff;
}
@media screen and (max-width: 430px) {
  .innovation_community_content h6 {
    font-size: 32px;
    line-height: 35px;
  }
}
.innovation_community_content p {
  font-family: graphik Trial;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #fff;
  margin: 20px 0;
}
.innovation_community_content button {
  display: flex;
  margin: auto;
  font-family: Graphik Trial;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  padding: 17px 40px;
  background-color: #e7a300;
  color: #fff;
  border: none;
  border-radius: 8px;
}
.innovation_line {
  position: absolute;
  top: 50px;
  right: 35%;
  z-index: -1;
}
@media screen and (max-width: 768px) {
  .innovation_line {
    right: 30%;
  }
}
.innovation_line2 {
  position: absolute;
  top: 30%;
  right: 41%;
}
@media screen and (max-width: 430px) {
  .innovation_line2 {
    right: 25%;
    top: 25%;
  }
}
.innovation_slider {
  display: flex;
  flex-wrap: wrap;
  margin-left: -20px;
  width: 100vw;
  margin: auto;
}
.innovation_imageCon {
  height: auto;
  overflow: hidden;
  background-color: white !important;
  position: relative;
  width: auto;
}
.innovation_imageCon {
  height: auto;
  overflow: hidden;
  background-color: white !important;
  position: relative;
  width: auto;
}
.innovation_image {
  width: 400px;
  height: 300px;
  border-radius: 8px;
}
.innovation_slide {
  animation: scrollRight 30s linear infinite;
  animation-direction: reverse;
  display: flex;
  width: 18000px;
  overflow: hidden;
  gap: 10px;
}
.innovation_slide2 {
  animation: scroll 20s linear infinite;
  display: flex;
  width: 18000px;
  overflow-x: hidden;
  gap: 10px;
  margin-bottom: 20px;
}
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-2450px);
  }
}
@keyframes scrollRight {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-2450px);
  }
}

.splide__arrows {
  position: absolute;
  bottom: -30px;
  right: 120px;
}
@media screen and (max-width: 768px) {
  .splide__arrows {
    right: 30px;
  }
}

.splide__pagination {
  position: absolute;
  bottom: 10px !important;
  right: 10px !important;
}

.tri {
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 30px solid #e9edfa;
}

.about {
  overflow-x: hidden;
}
@media (min-width: 1920px) {
  .about {
    max-width: 1920px;
    margin: 0 auto;
  }
}
.about_top {
  background: rgba(161, 148, 186, 0.1);
  position: relative;
}
.about_top-text {
  padding: 64px 120px 15vw;
}
@media (max-width: 1280px) {
  .about_top-text {
    padding: 64px 70px 15vw;
  }
}
@media (max-width: 1024px) {
  .about_top-text {
    padding: 64px 32px 15vw;
  }
}
@media (max-width: 600px) {
  .about_top-text {
    padding: 64px 16px 15vw;
  }
}
.about_top-text h1 {
  font-size: 64px;
  font-weight: 700;
  color: #151F10;
  line-height: 81.66px;
  letter-spacing: 1%;
  width: 65%;
}
@media (max-width: 1024px) {
  .about_top-text h1 {
    width: 75%;
  }
}
@media (max-width: 768px) {
  .about_top-text h1 {
    width: 100%;
  }
}
@media (max-width: 600px) {
  .about_top-text h1 {
    font-size: 32px;
    line-height: 40.83px;
    margin: 0 15px;
  }
}
.about_top-text p {
  font-size: 18px !important;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0%;
  color: #575A65;
}
@media (max-width: 600px) {
  .about_top-text p {
    margin: 0 15px;
  }
}
.about_top-images {
  display: flex;
  position: absolute;
  right: 0;
  left: 0;
  bottom: -20vw;
  box-sizing: border-box;
}
@media (max-width: 700px) {
  .about_top-images {
    bottom: -40vw;
  }
}
.about_top-images-item {
  width: 20vw;
  height: 30vw;
  border-radius: 15px;
  overflow: hidden;
  margin: 10px;
}
@media (max-width: 700px) {
  .about_top-images-item {
    width: 50%;
    height: 50vw;
  }
}
.about_top-images-item .item-inside {
  height: 49%;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 15px;
}
.about_top-images-item .item-inside2 {
  height: 49%;
  background: blue;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 15px;
}
.about_top-images-item .about1 {
  background: url("../images/website/newabout1.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.about_top-images-item .about2 {
  background: url("../images/website/newabout2.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.about_top-images-item .about3 {
  height: 100%;
}
@media (max-width: 700px) {
  .about_top-images-item .about3 {
    height: auto;
    width: 100%;
  }
}
.about_top-images-item .about4 {
  height: 100%;
}
.about_top-images-item .about5 {
  background: url("../images/website/newabout5.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.about_top-images-item .about6 {
  background: url("../images/website/newabout6.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.about_top-images-item .about7 {
  background: url("../images/website/newabout7.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.about_top-images-item .about8 {
  background: url("../images/website/newabout8.png");
  background-size: cover;
  background-repeat: no-repeat;
}
@media (max-width: 700px) {
  .about_top-images .item-hide {
    display: none;
  }
}
.about_space-div {
  height: 35vh;
  background: #FAF8FE;
}
@media (max-width: 1024px) {
  .about_space-div {
    height: 23vh;
  }
}
@media (max-width: 1280px) {
  .about_space-div {
    height: 30vh;
  }
}
.about_slide {
  margin-top: 50px;
}
@media (max-width: 700px) {
  .about_slide {
    margin-top: 90px;
  }
}
.about_slide p {
  font-size: 1rem;
  color: #575a65;
}
@media (max-width: 600px) {
  .about_slide p {
    font-size: 2rem;
  }
}
.about_slide h2 {
  font-size: 2.5em;
}
@media (max-width: 600px) {
  .about_slide h2 {
    font-size: 3.7rem;
  }
}
@media (max-width: 700px) {
  .about_slide .item-hide {
    display: none;
  }
}
.about_slide-impact {
  margin: 50px 15px 0;
  box-sizing: border-box;
}
@media (min-width: 750px) {
  .about_slide-impact-view {
    display: flex;
  }
}
.about_slide-impact-view img {
  margin: 5px;
  width: 100%;
  height: auto;
}
@media (min-width: 750px) {
  .about_slide-impact-view img {
    width: 33%;
  }
}
.about_define {
  background: rgba(207, 185, 249, 0.1);
  padding: 70px 120px;
  margin: 0px 0 100px;
}
@media (max-width: 1280px) {
  .about_define {
    padding: 70px;
  }
}
@media (max-width: 1024px) {
  .about_define {
    padding: 32px;
  }
}
@media (max-width: 600px) {
  .about_define {
    padding: 16px;
  }
}
.about_define .sub-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 48px;
  letter-spacing: 3%;
  color: #575a65;
}
.about_define p {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 3%;
  color: #575a65;
}
@media (max-width: 600px) {
  .about_define p {
    font-size: 2rem;
  }
}
.about_define h2 {
  color: #151F10;
  font-weight: 700;
  line-height: 50px;
  font-size: 38px;
  letter-spacing: 1%;
}
.about_define h3 {
  font-weight: 600;
  font-size: 24px;
  line-height: 30.62px;
  letter-spacing: 3%;
  margin: 15px 0;
  color: #151F10;
}
@media (max-width: 600px) {
  .about_define h3 {
    font-size: 2.7rem;
  }
}
@media (min-width: 600px) {
  .about_define-cards {
    display: flex;
  }
}
.about_define-cards-box1 {
  background: white;
  padding: 20px;
  margin: 20px 0px 10px;
  border-radius: 10px;
}
@media (min-width: 600px) {
  .about_define-cards-box1 {
    width: 33%;
    margin: 10px;
  }
}
.about-cards {
  display: flex;
}
.about-cards-box1 {
  width: 33%;
  background: white;
  padding: 20px;
  margin: 10px;
  border-radius: 10px;
}
.about_work {
  padding: 0 120px;
}
@media screen and (max-width: 1280px) {
  .about_work {
    padding: 0 70px;
  }
}
@media screen and (max-width: 1024px) {
  .about_work {
    padding: 0 32px;
  }
}
@media screen and (max-width: 600px) {
  .about_work {
    padding: 0 16px;
  }
}
.about_work p {
  font-size: 38px;
  font-weight: 700;
  letter-spacing: 1%;
  color: #151F10;
  line-height: 50px;
}
@media (max-width: 600px) {
  .about_work p {
    font-size: 24px;
    line-height: 30.62px;
  }
}
.about_work P:last-of-type {
  font-size: 12px !important;
  font-weight: 500;
  line-height: 15.31px;
  letter-spacing: 0%;
}
.about_work .options {
  color: #773DD3;
  margin: 3px 0 0 7px;
}
.about_work h2 {
  font-size: 14px;
  line-height: 48px;
  letter-spacing: 3%;
  color: #575A65;
}

.build-btn {
  color: #ffffff;
  background: #773DD3;
  border: 1px solid #773DD3;
  font-size: 1rem;
  padding: 15px 25px;
  margin: 20px 10px;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
}
@media (max-width: 600px) {
  .build-btn {
    font-size: 2rem;
  }
}
.build-btn:hover {
  color: white;
}

@media (max-width: 600px) {
  .build_case h1 {
    font-size: 3.5rem;
  }
}
@media (min-width: 700px) {
  .build_case-flex1 {
    display: flex;
    justify-content: space-between;
  }
}
.build_case-flex1_first {
  height: 250px;
  background-size: cover;
  border-radius: 20px;
  overflow: hidden;
  margin-bottom: 20px;
}
@media (min-width: 700px) {
  .build_case-flex1_first {
    margin-right: 20px;
    width: 60%;
  }
}
.build_case-flex1_second {
  background: url("../images/study2.png");
  border-radius: 20px;
  height: 250px;
}
@media (min-width: 700px) {
  .build_case-flex1_second {
    width: 40%;
  }
}
.build_case-flex2 {
  margin-top: 20px;
  justify-content: space-between;
}
@media (min-width: 700px) {
  .build_case-flex2 {
    display: flex;
  }
}
.build_case-flex2_first {
  margin-bottom: 20px;
  background: url("../images/study3.png");
  border-radius: 20px;
  height: 250px;
}
@media (min-width: 700px) {
  .build_case-flex2_first {
    width: 40%;
    margin-right: 20px;
  }
}
.build_case-flex2_second {
  background: url("../images/study4.png");
  border-radius: 20px;
  height: 250px;
}
@media (min-width: 700px) {
  .build_case-flex2_second {
    width: 60%;
  }
}
.build_case-flex3 {
  margin: 20px 0 50px;
}
@media (min-width: 700px) {
  .build_case-flex3 {
    display: flex;
    justify-content: space-between;
  }
}
.build_case-flex3_first {
  margin-bottom: 20px;
  height: 250px;
  background: url("../images/study5.png");
  border-radius: 20px;
}
@media (min-width: 700px) {
  .build_case-flex3_first {
    width: 50%;
    margin-right: 20px;
  }
}
.build_stories {
  background: #1F1035;
  padding: 80px 0 50px;
  position: relative;
  margin-bottom: 150px;
}
.build_stories-text {
  color: white;
  text-align: center;
}
@media (max-width: 600px) {
  .build_stories-text h1 {
    font-size: 3.5rem;
  }
}
@media (max-width: 600px) {
  .build_stories-text p {
    font-size: 2rem;
  }
}
.build_stories-bg {
  background: rgba(31, 16, 53, 0.1);
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  z-index: 1000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: fixed;
}
.build_stories-form {
  margin: 30px 25% 0;
  top: 10px;
  bottom: 20px;
  overflow-y: scroll;
  border-radius: 8px;
  padding: 20px;
  position: absolute;
  box-shadow: 0 0 3px #ccc;
  background: #ffffff;
}
@media (max-width: 550px) {
  .build_stories-form {
    margin: 30px 20px 0;
    box-sizing: border-box;
  }
}
.build_stories-form .form-input, .build_stories-form select {
  box-sizing: border-box;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 1px solid #e0e0e0;
  font-size: 1rem;
  height: 40px;
  padding: 0 10px;
  width: 100%;
  margin: 10px 5px;
}
@media (max-width: 600px) {
  .build_stories-form .form-input, .build_stories-form select {
    font-size: 2rem;
  }
}
.build_stories-form select {
  color: #575a65;
}
.build_stories-form textarea {
  box-sizing: border-box;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 1px solid #e0e0e0;
  font-size: 1rem;
  padding: 0 10px;
  width: 100%;
  margin: 25px 5px 10px;
}
@media (max-width: 600px) {
  .build_stories-form textarea {
    font-size: 2rem;
  }
}
.build_stories-form button {
  height: 40px;
  background: #773DD3;
  border: 1px solid #773DD3;
  color: white;
  border-radius: 8px;
  font-size: 0.9rem;
  margin: 10px 0;
  padding: 10px 25px;
}
@media (max-width: 600px) {
  .build_stories-form button {
    font-size: 2rem;
  }
}
.build_stories-form label {
  font-size: 1rem;
  color: #575a65;
}
@media (max-width: 600px) {
  .build_stories-form label {
    font-size: 2rem;
  }
}
.build_stories .fillform-btn {
  color: #ffffff;
  background: #773DD3;
  border: 1px solid #773DD3;
  font-size: 1rem;
  padding: 10px 25px;
  margin: 20px auto;
  border-radius: 5px;
  display: block;
}
@media (max-width: 600px) {
  .build_stories .fillform-btn {
    font-size: 2rem;
  }
}

/* The container */
.hear-about {
  position: relative;
  width: 200px;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.hear-about input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.hear-about:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.hear-about input:checked ~ .checkmark {
  background-color: #eee;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.hear-about input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.hear-about .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid #773DD3;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.case p {
  font-size: 1.4em;
}
.case h1 {
  font-size: 2.8em;
}
.case_toptext {
  margin: 90px 0 50px;
}
.case_challenge {
  display: flex;
  margin: 50px 0;
}
.case_challenge .first {
  width: 20%;
  margin-right: 20px;
}
.case_challenge .second {
  width: 78%;
}
.case_challenge-links {
  display: flex;
}
.case_challenge-links p {
  margin-right: 20px;
  text-decoration: underline;
}

.startup {
  height: 100%;
  width: 100%;
  margin-top: 5rem;
}
.startup__intro {
  height: 100%;
  width: 100%;
}
.startup__intro-container {
  width: 70%;
  margin: auto;
  text-align: center;
}
@media (max-width: 75em) {
  .startup__intro-container {
    width: 80%;
  }
}
@media (max-width: 56.25em) {
  .startup__intro-container {
    width: 90%;
  }
}
@media (max-width: 37.5em) {
  .startup__intro-container {
    width: 95%;
  }
}
.startup__intro-container h1 {
  margin-bottom: 2rem;
}
.startup__intro-btns {
  margin: auto;
  margin-top: 3rem;
  display: flex;
  justify-content: center;
}
.startup__areas {
  width: 100%;
  position: relative;
  margin-top: 8rem;
}
@media (max-width: 56.25em) {
  .startup__areas {
    margin-top: 5rem;
  }
}
.startup__areas-container {
  width: 85%;
  margin: auto;
  padding: 8rem 0;
}
@media (max-width: 75em) {
  .startup__areas-container {
    width: 90%;
  }
}
@media screen and (max-width: 1600px) {
  .startup__areas-container {
    width: 90% !important;
  }
}
.startup__areas-box {
  display: flex;
  justify-content: center;
  gap: 8px;
  overflow: hidden;
}
@media screen and (max-width: 625px) {
  .startup__areas-box {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 480px) {
  .startup__areas-box {
    grid-template-columns: repeat(1, 100%);
    row-gap: 50px;
  }
}
.startup__areas-header {
  grid-column: 3/-1;
  justify-self: center;
  width: 70%;
  text-align: center !important;
}
@media screen and (max-width: 1400px) {
  .startup__areas-header {
    width: 80%;
    grid-column: 1/-1;
    grid-row: 1/2;
    text-align: center;
    justify-self: stretch;
    width: 100%;
  }
}
.startup__areas-item {
  position: relative;
  box-shadow: 0px 4px 14px rgba(151, 161, 184, 0.1);
  border-radius: 6px;
  transition: all 0.3s ease-in;
  height: 391px;
  margin-bottom: 8px;
}
.startup__areas-item:hover {
  transform: translateY(-0.5rem) scale(1.05);
}
@media screen and (max-width: 1400px) {
  .startup__areas-item {
    align-self: flex-start !important;
  }
}
@media (max-width: 480px) {
  .startup__areas-item {
    grid-column: 1/-1 !important;
    justify-self: center;
    display: flex;
    justify-content: center;
  }
}
.startup__areas-item-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 2rem;
}
.startup__areas-item-container > h1 {
  margin-bottom: 1.5rem;
}
.startup__areas-item-container > div {
  width: 5rem;
  height: 5rem;
  border-radius: 6px;
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 625px) {
  .startup__areas-item {
    height: auto;
  }
}
.startup__values {
  background: #773dd3;
  background-image: url("../images/svg/pattern.svg");
  position: relative;
  margin-top: 10rem;
  width: 1200px;
  margin: auto;
  border-radius: 16px;
}
@media screen and (max-width: 1400px) {
  .startup__values {
    width: 90%;
  }
}
.startup__values-container {
  width: 85%;
  margin: auto;
  padding: 6rem 0;
}
@media (max-width: 75em) {
  .startup__values-container {
    width: 90%;
  }
}
.startup__values-header {
  margin-bottom: 3rem;
  display: flex;
  flex-direction: column;
}
.startup__values-items {
  display: grid;
  grid-template-columns: repeat(3, 30%);
  -moz-column-gap: 48px;
       column-gap: 48px;
  row-gap: 32px;
}
@media (max-width: 800px) {
  .startup__values-items {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 500px) {
  .startup__values-items {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 1rem;
  }
}
.startup__timeline {
  width: 100%;
  margin: 5rem 0;
}
.startup__timeline-container {
  width: 85%;
  margin: auto;
  padding: 0 0 4rem 0;
}
@media (max-width: 75em) {
  .startup__timeline-container {
    width: 90%;
  }
}
.startup__timeline-header {
  margin-bottom: 1rem;
}
.startup__timeline-list {
  margin-top: 3rem;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  justify-content: space-between;
}
@media screen and (max-width: 800px) {
  .startup__timeline-list {
    grid-template-columns: repeat(1, 100%);
  }
}
.startup__timeline-item {
  display: flex;
  flex-direction: column;
}
@media (max-width: 800px) {
  .startup__timeline-item {
    display: none;
  }
}
.startup__timeline-item:first-child {
  grid-column: 1/3;
}
.startup__timeline-item:first-child > p {
  background: #773dd3 !important;
  color: #ffffff;
}
@media screen and (max-width: 800px) {
  .startup__timeline-item:first-child > p {
    background-color: transparent !important;
    color: #575a65;
  }
}
.startup__timeline-item:nth-child(3) {
  grid-column: 5/-1;
}
.startup__timeline-item:nth-child(4) {
  grid-column: 2/3;
}
.startup__timeline-item:nth-child(5) {
  grid-column: 4/-1;
}
.startup__timeline-item:nth-child(4) > div > span::before, .startup__timeline-item:nth-child(5) > div > span::before {
  top: -9px !important;
}
.startup__timeline-item > p {
  background: rgba(119, 61, 211, 0.1);
  padding: 1rem 1.5rem;
  width: -moz-fit-content;
  width: fit-content;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.03em;
  color: #773dd3;
  margin-bottom: 0 !important;
}
.startup__timeline-meta {
  display: flex;
  position: relative;
}
.startup__timeline-meta > span {
  width: 3px;
  height: 100px;
  display: inline-block;
  background: rgba(119, 61, 211, 0.1);
  position: relative;
}
.startup__timeline-meta > span::before {
  position: absolute;
  bottom: -12px;
  left: -9px;
  width: 20px;
  height: 20px;
  content: "";
  background: #773dd3;
  border: 4px solid #f3efff;
  border-radius: 50%;
}
@media screen and (max-width: 800px) {
  .startup__timeline-meta > span::before {
    top: 0;
  }
}
.startup__timeline-meta > p {
  font-weight: 500;
  font-size: 1rem;
  line-height: 2rem;
  display: inline;
  letter-spacing: 0.03em;
  color: #773dd3;
  margin: 0 0 0 2rem;
  position: absolute;
  top: 30px;
}
.startup__involve {
  background: #fff;
  margin-top: 5rem;
}
.startup__involve-container {
  width: 85%;
  margin: auto;
  padding: 2.5rem 0;
}
@media (max-width: 75em) {
  .startup__involve-container {
    width: 90%;
  }
}
.startup__involve-items {
  display: flex;
  justify-content: center;
  gap: 8px;
  overflow: hidden;
}
@media screen and (max-width: 800px) {
  .startup__involve-items {
    flex-wrap: wrap;
  }
}
.startup__involve-list-container {
  width: 393px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 418px;
  background-color: #773dd3;
  background-image: url("../images/svg/stroke.svg");
  border-radius: 16px;
  padding: 67.5px;
}
@media (max-width: 75em) {
  .startup__involve-list-container {
    width: 85%;
  }
}
@media (max-width: 56.25em) {
  .startup__involve-list-container {
    width: 95%;
  }
}
@media screen and (max-width: 800px) {
  .startup__involve-list-container {
    align-items: center;
    text-align: center;
    padding: 5rem 2rem;
    max-width: 393px;
    margin: 0 auto;
    height: 343px;
  }
}
.startup__involve-list-container > h1 {
  margin-bottom: 3rem;
  color: white;
  font-weight: 500;
  font-size: 2rem;
  line-height: 3.125rem;
  letter-spacing: 0.01em;
}
.startup__involve-list-container > h1 span {
  color: #ffc823;
}
.startup__involve-list-container > button {
  align-self: flex-start;
  background-color: #fff !important;
  color: #773dd3;
}
@media screen and (max-width: 600px) {
  .startup__involve-list-container > button {
    align-self: center !important;
  }
}
@media screen and (max-width: 800px) {
  .startup__involve-list:nth-child(2) {
    border-right: none;
  }
  .startup__involve-list:last-child {
    grid-column: 1/-1;
    width: 50%;
    margin: auto;
  }
}
@media screen and (max-width: 600px) {
  .startup__involve-list {
    border: 1px solid #ffffff;
    border-radius: 10px;
  }
  .startup__involve-list:last-child {
    grid-column: 1/-1;
    width: 100%;
  }
  .startup__involve-list:nth-child(2) {
    border-right: 1px solid #ffffff;
  }
}
.startup__subscribe {
  margin-top: 10rem;
}

.mobile {
  height: 130px;
  position: relative;
  display: none;
}
@media screen and (max-width: 800px) {
  .mobile {
    display: block;
  }
}
.mobile:not(:last-child) > div > span::before {
  width: 3px;
  height: 114px;
  background-color: hsl(0, 0%, 98%);
  left: 8.3px;
  top: 46px;
  content: "";
  position: absolute;
}
.mobile__container {
  display: flex;
  overflow-x: hidden;
}
.mobile__container span {
  align-self: flex-end;
  width: 20px;
  height: 20px;
  display: inline-block;
  border-radius: 50%;
  background: #1f1035;
  border: 4px solid #f3efff;
  box-sizing: border-box;
}
.mobile__meta {
  margin-left: 4rem;
  width: 100%;
}
.mobile__meta p:first-of-type {
  font-family: Graphik;
  font-size: 12px;
  letter-spacing: 0.03em;
  color: #575a65;
  margin-bottom: 0;
}
.mobile__meta p:last-of-type {
  margin-top: 1rem;
  margin-bottom: 0;
  font-family: Graphik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.03em;
  color: #1f1035;
}

@media (max-width: 800px) {
  .value__container {
    padding: 0 !important;
  }
}
.value__container h2 {
  color: #f5f5f5 !important;
}
@media (max-width: 800px) {
  .value__content {
    margin-top: 24px !important;
  }
}
.value__content h1 {
  color: #fff !important;
}
.value__content p {
  color: #fff !important;
}

@media screen and (max-width: 800px) {
  .hide {
    display: none;
  }
}

.hide2 {
  display: none;
}
@media screen and (max-width: 800px) {
  .hide2 {
    display: flex;
  }
}

.contact {
  background: #1f1035;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100vw; /* viewport width */
  height: 100%; /* viewport height */
  overflow-y: auto;
  z-index: 100000;
}
.contact__container {
  width: 100%;
  padding: 4rem 0;
}
.contact__header {
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  padding: 1rem 0 0 0;
}
@media screen and (max-width: 610px) {
  .contact__header {
    width: 80%;
  }
}
.contact__header-cancel {
  width: 30px;
  height: 30px;
  background: #f2f2f2;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.contact__header-cancel > span {
  display: inline-block;
  font-size: 2rem;
  color: #152c5b;
  font-weight: bold;
}
.contact__form {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 4rem;
}
.contact__form__container {
  width: 610px;
  margin: auto;
  max-width: 80%;
}
.contact__form__container > h1 {
  font-weight: 600;
  font-size: 4.17rem;
  line-height: 4.6rem;
  text-align: center;
  color: #ffffff;
}
.contact__form__container > p {
  font-weight: normal;
  font-size: 1.5rem;
  line-height: 5rem;
  text-align: center;
  letter-spacing: 0.01em;
  color: #ffffff;
}
.contact__button {
  padding: 1.3rem 0;
  width: 100%;
  background: #ffffff;
  border-radius: 10px;
  letter-spacing: 0.03em;
  font-weight: 600;
  color: #773dd3;
  font-size: 1.3rem;
  border: none;
}
.contact__button:disabled {
  color: #fff !important;
  border-color: #a0a0a0 !important;
  background-color: #a0a0a0 !important;
  cursor: not-allowed !important;
}
.contact__meta {
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;
  color: #ffffff;
  margin-bottom: 3rem;
}
@media screen and (max-width: 600px) {
  .contact__meta {
    flex-direction: column;
  }
}
@media screen and (max-width: 600px) {
  .contact__meta-sm {
    margin-bottom: 3rem;
  }
}
.contact__meta-sm p {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.06em;
}
.contact__meta-sm div {
  margin-top: 2rem;
}
.contact__meta-sm div a:not(:last-child) {
  margin-right: 2rem;
}
.contact__meta > p {
  font-size: 16px;
  align-self: flex-end;
  letter-spacing: 0.06em;
  margin-bottom: 0;
}
@media screen and (max-width: 600px) {
  .contact__meta > p {
    align-self: flex-start;
  }
}
.contact__meta-adr {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 16px;
}
@media screen and (max-width: 600px) {
  .contact__meta-adr {
    margin-top: 3rem;
  }
}
.contact__meta-adr p {
  margin-bottom: 0 !important;
}
.contact__meta-adr p:first-of-type {
  font-weight: 600;
}

.blogs {
  width: 100%;
}
.blogs__welcome {
  background-color: #5726a6;
  position: relative;
}
.blogs__container {
  width: 85%;
  margin: auto;
  padding: 2rem 0 20rem 0;
  position: relative;
}
@media (max-width: 75em) {
  .blogs__container {
    width: 90%;
  }
}
.blogs__intro {
  display: flex;
  flex-direction: column;
}
.blogs__intro span {
  display: inline-block;
  font-family: Minion Pro;
  font-style: italic;
  font-weight: 700;
  letter-spacing: 0.01em;
  text-align: left;
  color: aliceblue;
  margin-bottom: 0;
}
.blogs__intro span:first-of-type {
  font-size: 1.875rem;
}
.blogs__intro span:last-of-type {
  font-size: 4.375rem;
  text-transform: capitalize;
}
.blogs__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  margin-top: 15rem;
}
@media (max-width: 56.25em) {
  .blogs__list {
    display: none;
  }
}
.blogs__item {
  font-weight: normal;
  font-size: 20px;
  line-height: 21px;
  letter-spacing: 0.03em;
  color: #ffffff;
}
.blogs__hero {
  background: #ffffff;
  box-shadow: 0px 4px 14px rgba(151, 161, 184, 0.1);
  border-radius: 10px;
  position: absolute;
  margin-top: 5rem;
  width: 100%;
  text-decoration: none;
}
.blogs__hero-container {
  padding: 4rem 2rem;
  display: flex;
  justify-content: space-around;
}
.blogs__hero-img {
  border-radius: 10px;
  margin-right: 2rem;
  width: 400px;
  height: 400px;
}
@media screen and (max-width: 800px) {
  .blogs__hero-img {
    display: none;
  }
}
.blogs__hero-img img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  -o-object-fit: cover;
     object-fit: cover;
}
.blogs__hero-content {
  flex-shrink: 0;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-basis: 50%;
}
@media screen and (max-width: 800px) {
  .blogs__hero-content {
    flex-basis: 100%;
    text-align: center;
  }
}
.blogs__hero-content h4 {
  font-weight: 600;
  font-size: 2.125rem;
  color: #151f10;
  width: 85%;
}
@media screen and (max-width: 800px) {
  .blogs__hero-content h4 {
    width: 100%;
    margin-bottom: 2rem;
    font-size: 3rem;
  }
}
.blogs__hero-content p {
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: justify;
  width: 85%;
  color: #575a65;
}
@media (max-width: 56.25em) {
  .blogs__hero-content p {
    width: 100%;
  }
}
.blogs__hero-content small {
  color: #575a65;
}
.blogs__blog {
  margin-top: 25rem;
  width: 100%;
  margin-bottom: 20rem;
}
@media screen and (max-width: 800px) {
  .blogs__blog {
    margin-top: 35rem;
  }
}
.blogs__blog-box {
  width: 85%;
  margin: auto;
}
@media (max-width: 75em) {
  .blogs__blog-box {
    width: 90%;
  }
}
.blogs__blog-container {
  display: grid;
  grid-template-columns: repeat(3, 400px);
  grid-template-rows: repeat(auto-fill, 360px);
  justify-content: space-between;
  row-gap: 10.8rem;
  -moz-column-gap: 2rem;
       column-gap: 2rem;
}
@media screen and (max-width: 1425px) {
  .blogs__blog-container {
    grid-template-columns: repeat(auto-fill, 300px);
    grid-template-rows: repeat(auto-fill, 300px);
  }
}
@media screen and (max-width: 890px) {
  .blogs__blog-container {
    grid-template-columns: repeat(auto-fill, 300px);
    grid-template-rows: repeat(auto-fill, 300px);
    justify-content: space-between;
    row-gap: 15.8rem;
  }
}
@media screen and (max-width: 700px) {
  .blogs__blog-container {
    grid-template-columns: repeat(1, 400px);
    grid-template-rows: repeat(1, 300px);
    justify-content: center;
  }
}
@media screen and (max-width: 450px) {
  .blogs__blog-container {
    grid-template-columns: repeat(1, 95%);
  }
}
.blogs__subscribe {
  margin-top: 10rem;
}
.blogs__loader {
  width: 100%;
  margin-top: 10rem;
  display: flex;
  justify-content: center;
}

.apply {
  font-family: graphik;
  width: 100%;
  height: 100%;
  position: relative;
}
.apply__container {
  height: 100%;
  width: 100%;
  display: flex;
}
.apply__aside {
  height: 100vh;
  background-color: #f7f9fc;
  position: sticky;
  flex-basis: 25%;
  top: 0;
  align-self: stretch;
}
@media screen and (max-width: 640px) {
  .apply__aside {
    display: none;
  }
}
.apply__aside-box {
  background: #ffffff;
  border-radius: 5px;
  width: 70%;
  margin: 5rem 5rem 0 5rem;
}
.apply__aside-container {
  width: 100%;
}
.apply__aside-container h6 {
  font-size: 14px;
  padding-top: 1rem;
  padding-left: 1rem;
  letter-spacing: 0.04em;
  color: #575a65;
}
.apply__aside-list {
  margin-top: 1rem;
  padding: 0;
  list-style: none;
}
.apply__aside-item {
  margin-bottom: 0.2rem;
  padding: 0.9rem 0.9rem 0.9rem 3rem;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}
.apply__aside-item span {
  font-size: 14px;
}
.apply__aside-item span:last-of-type {
  margin-left: 15px;
}
.apply__form {
  flex-grow: 1;
}
.apply__form-container {
  width: 90%;
  margin: 5rem auto 0 auto;
  padding-bottom: 3rem;
}
@media screen and (max-width: 640px) {
  .apply__form-container {
    width: 90%;
  }
}
.apply__form-container > p {
  text-align: center;
  margin-bottom: 0 !important;
  font-size: 2rem !important;
}
.apply__form-link {
  display: block;
  width: 28px;
  height: 33px;
}
.apply__form-link img {
  width: 100%;
  height: 100%;
}
.apply__form-founders-box > .form-group:first-child {
  grid-column: 1/-1;
  width: 48%;
}
@media screen and (max-width: 800px) {
  .apply__form-founders-box > .form-group:first-child {
    width: 100%;
  }
}
.apply__form-product-box > .form-group {
  grid-column: 1/-1;
}
.apply__form-progress-box > .form-group:last-child {
  grid-column: 1/-1;
}
.apply-form {
  width: 100%;
}
.apply-form-box {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  -moz-column-gap: 50px;
       column-gap: 50px;
  row-gap: 30px;
}
@media screen and (max-width: 800px) {
  .apply-form-box {
    grid-template-columns: repeat(1, 1fr);
  }
}
.apply__btns {
  display: flex;
  justify-content: flex-start;
}
@media screen and (max-width: 640px) {
  .apply__btns {
    align-items: center;
    flex-direction: column;
  }
}
.apply__btn {
  border-radius: 5px;
  padding: 1.5rem 2.5rem;
  border: none;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;
}
.apply__btn:first-child {
  background: #773dd3;
  color: #ffffff;
  margin-right: 2rem;
}
.apply__btn:first-child:disabled {
  color: #fff !important;
  border-color: #a0a0a0 !important;
  background-color: #a0a0a0 !important;
  cursor: not-allowed !important;
}
.apply__btn:last-child {
  border: 1px solid #773dd3;
  background-color: transparent;
  color: #773dd3;
}
.apply__btn:last-child:disabled {
  color: #fff !important;
  border-color: #a0a0a0 !important;
  background-color: #a0a0a0 !important;
  cursor: not-allowed !important;
}
@media screen and (max-width: 640px) {
  .apply__btn {
    width: 100%;
    margin-top: 2rem;
    margin-right: 0 !important;
  }
}

.selected {
  background-color: #f3efff;
}
.selected span {
  color: #773dd3;
  font-weight: bold;
}

.unsubscribe {
  width: 100%;
}
.unsubscribe__container {
  display: flex;
  justify-content: center;
  margin: 10rem 0 0 0;
}
@media screen and (max-width: 600px) {
  .unsubscribe__container {
    justify-content: stretch;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}
.unsubscribe__link {
  width: 150px;
  height: 43.46px;
  display: block;
}
.unsubscribe__link img {
  width: 100%;
}
.unsubscribe__box {
  font-family: graphik;
  margin-top: 3rem;
  border: 1px solid #e3e8ef;
  border-radius: 10px;
  padding: 3rem 5rem 4rem 3rem;
  margin-bottom: 10rem;
}
.unsubscribe__box h2 {
  font-size: 24px !important;
}
.unsubscribe__radios {
  margin-top: 3rem;
}
.unsubscribe__radio {
  margin-bottom: 2rem;
}
.unsubscribe__label {
  cursor: pointer;
  display: flex;
  align-items: center;
  display: flex;
}
.unsubscribe__label span:first-of-type {
  position: relative;
  border: 1px solid #773dd3;
  height: 1.5625rem;
  width: 1.5625rem;
  border-radius: 50%;
  margin-right: 1rem;
  display: block;
}
.unsubscribe__label span:first-of-type::before {
  content: "";
  position: absolute;
  height: 1.2rem;
  width: 1.2rem;
  background-color: #773DD3;
  border-radius: 50%;
  opacity: 0;
  transition: opacity 0.5s;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.unsubscribe__input:checked + .unsubscribe__label > span:first-of-type::before {
  opacity: 1;
}
.unsubscribe__textarea {
  margin-top: 3rem;
  width: 100%;
}
.unsubscribe__textarea textarea {
  width: 485px;
  min-height: 142px;
  max-height: 142px;
  padding: 1rem 0 0 1rem;
  font-size: 16px;
  border: 1px solid #e3e8ef;
  box-sizing: border-box;
  border-radius: 5px;
}
.unsubscribe__textarea textarea:focus {
  outline: none;
}
.unsubscribe__textarea textarea::-webkit-input-placeholder {
  color: #575a65;
}
@media screen and (max-width: 600px) {
  .unsubscribe__textarea {
    width: 100%;
  }
}
.unsubscribe__btn button {
  padding: 1.56rem 3.75rem;
}/*# sourceMappingURL=main.css.map */