.sidebar {
  background-color: #2d3748;
  position: fixed;
  top: 0;
  bottom: 0;

  width: 100vw; /* viewport width */
  height: 100vh; /* viewport height */
  overflow-y: scroll;
  overflow-x: hidden;

  z-index: 100000;
  transition: background-image 0.8s cubic-bezier(0.86, 0, 0.07, 1);
  @media screen and (min-width: 800px) {
    display: none;
  }

  &__container {
    width: 90%;
    margin: auto;
    padding: 6rem 0;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__cancel {
    background: rgba(151, 161, 184, 0.1);
    border-radius: 3px;
    width: 25px;
    height: 25px;
    display: flex;
    place-items: center;
    justify-content: center;
  }

  &__nav {
    margin-top: 10rem;
    position: relative;
  }

  &__items {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
  }

  &__item {
    padding-bottom: 4rem;
    margin-bottom: 5rem;
    &:not(:last-child) {
      border-bottom: 1px solid #ffffff;
    }
  }

  &__link {
    &:link,
    &:visited {
      font-family: "graphik";
      font-weight: 500;
      font-size: 14px;
      line-height: 15px;
      text-decoration: none;
      color: #ffffff;
      display: block;
    }

    &:focus {
      color: #ffffff;
    }
  }

  &__contact {
    background: #773dd3;
    border-radius: 5px;
    color: white;

    button{
      width:  100%;
    border: none;  
    }
  }

  // &__nav {
  //   height: 100%;
  //   width: 100%;

  //   ul {
  //     height: 100%;
  //     display: flex;
  //     margin: 0;
  //     padding: 0;
  //     list-style: none;
  //     align-items: center;
  //     flex-direction: column;
  //     justify-content: center;
  //     width: 50%;
  //     margin: auto;

  //     li {
  //       width: 100%;
  //       &:not(:last-child) {
  //         margin: 0 0 3rem 0 !important;
  //       }
  //       a {
  //         width: 100%;
  //         display: inline-block;
  //         text-align: center;
  //         font-size: 2rem;
  //         // background-color: white;
  //         color: white;
  //         padding: 2rem 0;
  //         text-transform: uppercase;

  //         background-size: 220%;
  //         transition: all 0.4s;

  //         &:hover {
  //           background-position: 100%;
  //           color: $color-main;
  //           transform: translateX(1rem);
  //           background-image: linear-gradient(
  //             119deg,
  //             transparent 0%,
  //             transparent 50%,
  //             #ffffff 50%
  //           );
  //         }
  //       }
  //     }
  //   }
  // }
}
