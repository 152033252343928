.svg-circle1 {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: rgba(119, 61, 211, 0.1);
  position: relative;
  img {
    position: absolute;
    left: 30%;
    top: 30%;
  }
}
.landing {
  box-sizing: border-box;

  &__blog {
    margin-top: 10rem !important;

    &-header {
      margin-bottom: 4rem;
      display: flex;
      justify-content: space-between;

      @media screen and (max-width: 450px) {
        & > a {
          display: none;
        }
      }
    }
  }
  &__partners {
    width: 100%;
    margin-bottom: 8rem;
    margin-top: 3rem;
    &-box {
      @include container;

      p {
        color: $color-main !important;
      }
    }
    &-text {
      text-align: center;
    }
    &-container {
      display: flex;
      justify-content: space-between;
      margin-top: 4rem;
      @media screen and (max-width: 930px) {
        display: none;
        // grid-template-columns: repeat(5, 1fr);
        // column-gap: 1rem;
        // justify-content: center;
      }

      @media screen and (max-width: 900px) {
        // width: 90%;
      }

      @media screen and (max-width: 848px) {
        grid-template-columns: repeat(3, 1fr);
        row-gap: 4rem;
      }

      @media screen and (max-width: 600px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media screen and (max-width: 320px) {
        grid-template-columns: repeat(1, 95%);
        row-gap: 6rem;
      }
    }

    &-img {
      width: 100%;
      height: auto;
      align-self: center;
      display: flex;

      @media screen and (max-width: 848px) {
        justify-content: center;
      }

      @media screen and (max-width: 320px) {
        grid-column: 1 / -1 !important;
      }

      &:not(:last-child) {
        margin-right: 1rem;
        @media screen and (max-width: 930px) {
          margin-right: 0;
        }
      }
      &:nth-child(3) {
        @media screeN and (max-width: 600px) {
          grid-row: 2 / 3;
        }
      }
      &:nth-child(4) {
        @media screen and (max-width: 848px) {
          grid-column: 1/ 3;
        }
        @media screen and (max-width: 600px) {
          width: 100%;
          grid-column: 1/ -1;

          img {
            width: 100%;
            height: auto;
          }
        }
      }

      &:last-child {
        flex-direction: column !important;
        align-items: center !important;
        @media screen and (max-width: 848px) {
          grid-column: 3 /-1;
        }
        @media screen and (max-width: 600px) {
          grid-row: 2 / 3;
          grid-column: 2 /3;
        }

        @media screen and (max-width: 320px) {
          grid-row: 1/2;
        }
      }
    }
  }
  &_pattern {
    position: relative;
  }
  &_hero {
    text-align: center;
    margin: 100px auto 70px auto;
    width: 95%;
    // &_btn{
    //   text-align: center;
    // }
    @media (min-width: 992px) {
      max-width: 800px;
      display: block;
      margin: 100px auto 70px;
    }
    h6 {
      font-size: 1rem;
      font-weight: 600;
      &:first-child {
        color: $color-main;
      }
      &:last-child {
        font-size: 1.1rem;
        @media (max-width: 600px) {
          font-size: 2rem;
        }
      }
      @media (max-width: 600px) {
        font-size: 2rem;
      }
    }
    p {
      font-size: 1rem;

      &:first-child {
        color: $color-main;
      }
      &:last-child {
        font-size: 1.1rem;
        @media (max-width: 600px) {
          font-size: 2rem;
        }
      }
      @media (max-width: 600px) {
        font-size: 2rem;
      }
    }
    h1 {
      color: #1f1035;
      font-size: 3.5rem;
      font-weight: 700;

      @media (max-width: 600px) {
        font-size: 3rem;
      }
      span {
        text-shadow: 2px 2px #d4dfff;
      }
    }
    .purple-btn {
      color: $color-white;
      background: $color-main;
      border: 1px solid $color-main;
      font-size: 1rem;
      padding: 10px 25px;
      margin: 20px 10px;
      border-radius: 5px;
      @media (max-width: 600px) {
        font-size: 2rem;
      }
    }
    .whitebg-btn {
      color: $color-main;
      background: $color-white;
      border: 2px solid $color-main;
      font-size: 1rem;
      padding: 10px 25px;
      margin: 20px 10px;
      border-radius: 5px;
      @media (max-width: 600px) {
        font-size: 2rem;
      }
    }
    .pattern_1 {
      position: absolute;
      bottom: -70px;
      right: 0;
      z-index: 0;
      @media (max-width: 700px) {
        width: 100%;
      }
    }
    .pattern_2 {
      position: absolute;
      bottom: -70px;
      z-index: 0;
      left: 0;
      @media (max-width: 700px) {
        display: none;
      }
    }

    &_btn {
      display: flex;
      justify-content: center;
      margin-top: 2rem;
      position: relative;
      z-index: 100;
    }
  }

  .nextbigthing {
    background: url("../images/nextbigthing.png");
    background-size: cover;
    background-repeat: no-repeat;
    padding: 70px 0;
    box-sizing: border-box;
    margin-bottom: 10rem;
    @media (max-width: 700px) {
      background: url("../images/nextbigthing2.png");
      background-size: cover;
    }
  }

  &_wwd {
    background-color: rgba(243, 239, 255, 0.3);
    padding: 70px 0;
    box-sizing: border-box;
    // @include container;

    &-textpart {
      p {
        font-size: 1rem;
        @media (max-width: 600px) {
          font-size: 2rem;
        }
        &:first-child {
          color: $color-main;
        }
        &:last-child {
          font-size: 1.1rem;
          @media (max-width: 600px) {
            font-size: 2rem;
          }
        }
      }
      h1 {
        color: #1f1035;
        font-size: 2.5em;
        margin-bottom: 20px;
        font-weight: bold;
        @media (max-width: 600px) {
          font-size: 3.7rem;
        }
        span {
          text-shadow: 2px 2px #d4dfff;
        }
      }
    }

    &-columns {
      // @include container;
      width: 90%;
      margin: auto;
      @media (min-width: 1940px) {
        width: 85%;
      }
      @media (min-width: 450px) {
        display: flex;
      }
      .section1 {
        width: 33%;
        padding: 20px;
        @media (max-width: 450px) {
          width: 100%;
          padding: 10px 20px;
        }
      }
    }
    &-four {
      h3 {
        color: #1f1035;
        margin: 20px 0 10px;
        font-size: 1.4em;
        font-weight: 700;
        @media (max-width: 600px) {
          font-size: 2.5rem;
        }
      }
      p {
        font-size: 1rem;
        color: #575a65;
        @media (max-width: 600px) {
          font-size: 2rem;
        }
        &:last-child {
          color: $color-main;
          margin-bottom: 30px;
        }
      }
    }

    &-column1 {
      @media (min-width: 450px) {
        display: flex;
      }

      .section2 {
        a {
          width: 50%;

          @media (max-width: 600px) {
            width: 100%;
          }
        }
        @media (min-width: 555px) {
          width: 50%;
        }

        .business {
          width: 80%;
          display: block;
          margin: auto;
          @media (max-width: 800px) {
            margin: 100px auto;
          }
          @media (max-width: 450px) {
            margin: auto;
          }
        }
      }

      .section3 {
        padding: 20px;
      }

      h1 {
        color: #1f1035;
        font-size: 2.4em;
        margin: 50px 10px 20px;
        font-weight: 700;
        @media (max-width: 700px) {
          color: white;
        }
        @media (max-width: 600px) {
          font-size: 3.5rem;
        }
      }
      p {
        font-size: 1rem;
        color: #575a65;
        padding: 10px 10px 0;
        @media (max-width: 700px) {
          color: white;
        }
        @media (max-width: 600px) {
          font-size: 2rem;
        }
      }
      button {
        color: $color-white;
        background: $color-main;
        border: 1px solid $color-main;
        font-size: 1rem;
        padding: 10px 55px 10px 25px;
        margin: 20px 10px;
        border-radius: 5px;
        position: relative;
        @media (max-width: 600px) {
          font-size: 2rem;
        }

        img {
          width: 23px;
          position: absolute;
          top: 10px;
          right: 10px;
        }
      }
    }
  }

  &_featured {
    padding: 100px 0;
    box-sizing: border-box;
    &-text {
      p {
        font-size: 1rem;
        color: #575a65;
        @media (max-width: 600px) {
          font-size: 2rem;
        }
      }
      h1 {
        color: #1f1035;
        font-size: 2.4em;
        margin-bottom: 40px;
        @media (max-width: 600px) {
          font-size: 3.5rem;
        }
      }
    }
    &-logos {
      display: grid;
      grid-row-gap: 30px;
      grid-template-columns: auto auto auto auto auto;

      @media (max-width: 769px) {
        grid-template-columns: auto auto auto;
      }
      @media (max-width: 450px) {
        grid-template-columns: auto auto;
        img {
          display: block;
          margin: auto;
        }
      }

      img {
        width: 60%;
      }
    }
  }

  &_news {
    margin: 100px 0;
    &-heading {
      display: flex;
      margin-bottom: 40px;

      h1 {
        margin-top: 30px;
      }
      .blog-btn {
        margin-left: auto;
        order: 2;
      }
      button {
        color: $color-white;
        background: $color-main;
        border: 1px solid $color-main;
        font-size: 1rem;
        padding: 10px 55px 10px 25px;
        margin: 20px 10px;
        border-radius: 5px;
        position: relative;
        @media (max-width: 600px) {
          font-size: 2rem;
        }

        img {
          width: 23px;
          position: absolute;
          top: 10px;
          right: 10px;
        }
      }
    }

    &-cards {
      display: grid;
      grid-row-gap: 30px;
      grid-template-columns: auto auto auto;

      @media (max-width: 992px) {
        grid-template-columns: auto auto;
      }
      @media (max-width: 570px) {
        grid-template-columns: auto;
      }
      &-boxes {
        height: 250px;
        width: 95%;
        border-radius: 10px;
        background: gray;
        display: block;
        margin: 0 auto 50px;
        position: relative;
        // overflow: hidden;
        @media (max-width: 570px) {
          width: 100%;
        }

        img {
          width: 100%;
          height: auto;
        }

        .small-box {
          height: 105px;
          position: absolute;
          width: 80%;
          border-radius: 10px;
          bottom: -10px;
          background-color: white;
          box-shadow: 0px 4px 14px rgba(151, 161, 184, 0.1);
          z-index: 300;
          padding: 10px;
          left: 20px;
          right: 20px;

          h4 {
            font-size: 1.4em;
            @media (min-width: 900px) {
              font-size: 1rem;
            }
            @media (max-width: 600px) {
              font-size: 2.2rem;
            }
          }
          p {
            color: #575a65;
            font-size: 1rem;
            @media (min-width: 900px) {
              font-size: 1rem;
            }
            @media (max-width: 600px) {
              font-size: 2rem;
            }
          }
        }
      }
    }
  }
  &_subscription {
    width: 500px;
    display: block;
    margin: auto;
    box-sizing: border-box;
    @media (max-width: 550px) {
      width: 90%;
    }
    p {
      font-size: 1rem;
      @media (max-width: 600px) {
        font-size: 1.8rem;
      }
    }
    input {
      width: 100%;
      height: 40px;
      font-size: 1rem;
      border: 1px solid #f5f6f8;
      box-sizing: border-box;
      border-radius: 8px;
      padding: 0 15px;
      @media (max-width: 600px) {
        font-size: 2rem;
      }
    }
    img {
      position: absolute;
      right: 20px;
      width: 30px;
      top: 5px;
    }
  }
}

// STYLING EFFECTS
/* Hang */
@-webkit-keyframes hvr-hang {
  0% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
  50% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
  }
  100% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
}
@keyframes hvr-hang {
  0% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
  50% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
  }
  100% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
}
@-webkit-keyframes hvr-hang-sink {
  100% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
}
@keyframes hvr-hang-sink {
  100% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
}
.hvr-hang {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-animation-name: hvr-hang-sink, hvr-hang;
  animation-name: hvr-hang-sink, hvr-hang;
  -webkit-animation-duration: 0.3s, 1.5s;
  animation-duration: 0.3s, 1.5s;
  -webkit-animation-delay: 0s, 0.3s;
  animation-delay: 0s, 0.3s;
  -webkit-animation-timing-function: ease-out, ease-in-out;
  animation-timing-function: ease-out, ease-in-out;
  -webkit-animation-iteration-count: 1, infinite;
  animation-iteration-count: 1, infinite;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-direction: normal, alternate;
  animation-direction: normal, alternate;
}

/* Buzz */
@-webkit-keyframes hvr-buzz {
  50% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  100% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
}
@keyframes hvr-buzz {
  50% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  100% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
}
.hvr-buzz {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-animation-name: hvr-buzz;
  animation-name: hvr-buzz;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

/* Bounce In */
.hvr-bounce-in {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}
.hvr-bounce-in:hover,
.hvr-bounce-in:focus,
.hvr-bounce-in:active {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
  -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
  transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
}

/* Grow */
.hvr-grow {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}
.hvr-grow:hover,
.hvr-grow:focus,
.hvr-grow:active {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

/* Wobble Horizontal */
@-webkit-keyframes hvr-wobble-horizontal {
  16.65% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }
  33.3% {
    -webkit-transform: translateX(-6px);
    transform: translateX(-6px);
  }
  49.95% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }
  66.6% {
    -webkit-transform: translateX(-2px);
    transform: translateX(-2px);
  }
  83.25% {
    -webkit-transform: translateX(1px);
    transform: translateX(1px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes hvr-wobble-horizontal {
  16.65% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }
  33.3% {
    -webkit-transform: translateX(-6px);
    transform: translateX(-6px);
  }
  49.95% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }
  66.6% {
    -webkit-transform: translateX(-2px);
    transform: translateX(-2px);
  }
  83.25% {
    -webkit-transform: translateX(1px);
    transform: translateX(1px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
.hvr-wobble-horizontal {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvr-wobble-horizontal:hover,
.hvr-wobble-horizontal:focus,
.hvr-wobble-horizontal:active {
  -webkit-animation-name: hvr-wobble-horizontal;
  animation-name: hvr-wobble-horizontal;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}
.landing__partners-img {
  display: flex !important  ;
  justify-content: center !important;
  width: 30% !important;
}

.landing__partners-img img {
  display: block;
  width: auto;
  height: auto;
}
.landing__partners-wrapper{
  display: none;
  margin-top: 30px !important;
  @media  screen and (max-width:930px) {
    display: inline;
  }
}